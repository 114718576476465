define('bottomline-execution/utils/initials', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function initials(firstName, lastName) {
    var i = function i(name) {
      if (!name) {
        return '';
      }

      return name.split(' ').map(function (item) {
        return item.charAt(0);
      }).join('.') + '.';
    };

    return '' + i(firstName) + i(lastName);
  }

  exports.initials = initials;
});