define('bottomline-execution/services/ajax', ['exports', 'ember-ajax/services/ajax', 'bottomline-execution/config/environment'], function (exports, _ajax, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    requestHeaders: Ember.inject.service(),

    host: Ember.computed('appState.domain', function () {
      return this.get('appState.domain') + '/' + _environment.default.api;
    }),
    headers: Ember.computed('session.isAuthenticated', function () {
      return this.get('requestHeaders').defaultHeaders({ authenticate: true });
    })
  });
});