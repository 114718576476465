define('bottomline-execution/models/storage', ['exports', 'ember-data', 'bottomline-execution/utils/math-util'], function (exports, _emberData, _mathUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    selected: attr('boolean', { defaultValue: false }),
    StorageGroupID: attr('string'),
    SiteID: attr('string'),
    MaterialOwnerCode: attr('string'),
    maxStorageQty: attr('number'),
    MaterialID: attr('string'),
    SequenceNb: attr('number'),
    InventoryMethod: attr('number'),
    usableStorageQty: attr('number', { defaultValue: 0 }),
    totalStorageQty: attr('number', { defaultValue: 0 }),
    latitude: attr('number'),
    longitude: attr('number'),
    averageSalesQty: attr('number'),
    RunOutDT: attr('date'),
    RealRunOutDT: attr('date'),

    site: belongsTo('site'),
    recent_inventory: belongsTo('inventory', { async: false }),
    recent_storage_material: belongsTo('storage-material'),

    material: Ember.computed('MaterialID', 'isLoaded', function () {
      if (this.get('MaterialID')) {
        return this.store.peekRecord('material', this.get('MaterialID'));
      }
    }),
    materialOrRecentReplaceableMaterial: Ember.computed('material', function () {
      var material = this.get('material');

      if (!material.get('ReplaceableYN')) {
        return material;
      }

      var recentStorageMaterial = this.get('recent_storage_material');
      if (Ember.isEmpty(recentStorageMaterial)) {
        return material;
      }
      var recentReplaceableMaterial = recentStorageMaterial.get('material');
      if (!Ember.isEmpty(recentReplaceableMaterial)) {
        return recentReplaceableMaterial;
      } else {
        return material;
      }
    }),
    materialSeller: Ember.computed('MaterialOwnerCode', function () {
      return this.store.peekRecord('material-seller', this.get('MaterialOwnerCode'));
    }),
    availableQty: Ember.computed('recent_inventory.inventoryQty', 'usableStorageQty', function () {
      if (!this.get('recent_inventory')) {
        return 0;
      }

      return (0, _mathUtil.round)(Number(this.get('usableStorageQty')) - Number(this.get('recent_inventory').get('inventoryQty')) || 0, 3);
    }),
    currentQty: Ember.computed('recent_inventory.inventoryQty', function () {
      if (!this.get('recent_inventory')) {
        return 0;
      }

      return (0, _mathUtil.round)(Number(this.get('recent_inventory.inventoryQty')), 3);
    })
  });
});