define('bottomline-execution/routes/truck-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    customerConfiguration: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    model: function model() {
      var _this = this;

      return this.get('customerConfiguration').loadSettings(this.get('appState.currentTruck')).then(function () {
        return _this.get('appConfiguration.truckSettings');
      });
    }
  });
});