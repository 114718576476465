define('bottomline-execution/components/site-load-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    selectedTripStop: null,
    tripStopStorages: Ember.computed.alias('selectedTripStop.trip_stop_storages'),
    areDetailsShown: false,

    sortedTripStopStorages: Ember.computed('tripStopStorages', function () {
      var tripStopStorages = this.get('tripStopStorages');
      return Ember.isPresent(tripStopStorages) ? tripStopStorages.sortBy('storage.SequenceNb') : null;
    }),

    actions: {
      toggleDetails: function toggleDetails() {
        this.toggleProperty('areDetailsShown');
      }
    }
  });
});