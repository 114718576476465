define('bottomline-execution/services/configuration-ajax', ['exports', 'bottomline-execution/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    requestHeaders: Ember.inject.service(),

    getTabletConfiguration: function getTabletConfiguration(configAlias) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('requestHeaders').headers().then(function (headers) {
          _this.get('ajax').request(_environment.default.configurationEndpoint + "/tablet_configurations.json", {
            method: 'GET',
            data: {
              config_alias: configAlias
            },
            headers: headers
          }).then(function (response) {
            resolve(response);
          }, function (error) {
            reject(error);
          });
        });
      });
    },
    updateTabletConfiguration: function updateTabletConfiguration(params) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.get('requestHeaders').headers().then(function (headers) {
          _this2.get('ajax').request(_environment.default.configurationEndpoint + "/tablets/" + headers['device-guid'] + "/configuration", {
            method: 'PATCH',
            data: {
              configuration: params
            },
            headers: headers
          }).then(function (response) {
            resolve(response);
          }, function (error) {
            reject(error);
          });
        });
      });
    }
  });
});