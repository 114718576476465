define('bottomline-execution/components/input-number-spinner', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    value: null,
    min: null,
    max: null,
    allowEmpty: true,
    changeInput: null,
    _previousValue: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_previousValue', this.get('value'));
    },
    _value: function _value() {
      return this.get('value') || 0;
    },
    _isValid: function _isValid(value) {
      if (Ember.isEmpty(value) && this.get('allowEmpty')) {
        return true;
      }

      var parsedValue = parseFloat(value);

      if (!this._isNumber(parsedValue)) {
        return false;
      }

      var min = parseFloat(this.get('min'));
      var max = parseFloat(this.get('max'));

      if (this._isNumber(min) && parsedValue < min) {
        return false;
      }

      if (this._isNumber(max) && parsedValue > max) {
        return false;
      }

      return true;
    },
    _isNumber: function _isNumber(value) {
      return !isNaN(+value) && isFinite(value);
    },
    _changeValue: function _changeValue(value) {
      var newValue = Ember.isEmpty(value) ? this._value() : value;

      if (this._isValid(newValue)) {
        this.set('_previousValue', newValue);
        this.set('value', newValue);
      } else {
        this.set('value', this.get('_previousValue'));
      }

      if (this.changeInput) {
        this.changeInput();
      }
    },
    click: function click() {
      return false;
    },
    keyUp: function keyUp(ev) {
      var val = this.get('value');
      if (ev.key === 'Backspace') {
        if (val === 0) {
          return;
        }

        this.set('value', val.slice(0, val.length));
      }
    },


    actions: {
      increaseValue: function increaseValue() {
        var parsedValue = parseFloat(this._value());

        if (this._isNumber(parsedValue)) {
          this._changeValue(parsedValue + 1);
        }
      },
      decreaseValue: function decreaseValue() {
        var parsedValue = parseFloat(this._value());

        if (this._isNumber(parsedValue)) {
          this._changeValue(parsedValue - 1);
        }
      },
      changeValue: function changeValue() {
        this._changeValue();
      }
    }
  });
});