define('bottomline-execution/models/shift', ['exports', 'ember-data', 'bottomline-execution/mixins/trip-sorter'], function (exports, _emberData, _tripSorter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend(_tripSorter.default, {
    startable: true,

    ResourceID: attr('string'),
    ResourceName: attr('string'),
    Status: attr('string'),
    PlannedStartDT: attr('date'),
    ConfirmedStartDT: attr('date'),
    ConfirmedEndDT: attr('date'),
    state: attr('string'),
    remarks: attr('string'),
    TruckID: attr('string'),
    TruckLicense: attr('string'),
    TrailerID: attr('string'),
    RegionID: attr('string'),
    TrailerLicense: attr('string'),
    OdometerStart: attr('number'),
    OdometerEnd: attr('number'),
    question_list_before: attr('number'),
    question_list_after: attr('number'),
    adr_report_template_name: attr('string'),
    truck: attr(),
    trailer: attr(),

    truck_inventories: hasMany('truck-inventory', { async: false }),
    truck_storages: hasMany('truck-storage', { async: false }),
    trips: hasMany('trips', { async: true }),

    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    nextEvents: Ember.computed('state', function () {
      var _this = this;

      var events = Ember.A(this.get("store.shiftMeta").shiftEvents);
      return events.filter(function (event) {
        return Ember.A(event.options.from).includes(_this.get('state'));
      });
    }),
    nextStates: Ember.computed('state', 'canStart', 'canFinish', function () {
      var _this2 = this;

      return this.get('nextEvents').map(function (event) {
        var state = { action: event.name, state: event.options.to, disabled: false };

        if (state.state === 'started') {
          state.enabled = _this2.get('canStart');
          state.disabled = !state.enabled;
        }

        if (state.state === 'finished') {
          state.enabled = _this2.get('canFinish');
          state.disabled = !state.enabled;

          if (state.disabled && !_this2.get('hasStartedTrips')) {
            state.isHidden = true;
          }
        }

        if (state.action === 'cancel' && _this2.get('hasStartedTrips')) {
          state.isHidden = true;
        }

        return state;
      });
    }),
    hasStartedTrips: Ember.computed('trips.@each.state', function () {
      return !this.get('trips').every(function (trip) {
        return trip.get('state') === 'planned';
      });
    }),
    started: Ember.computed.equal('state', 'started'),
    finished: Ember.computed.equal('state', 'finished'),
    shifts: Ember.computed(function () {
      return this.store.peekAll('shift').filterBy('TruckID', this.get('appState.currentTruck'));
    }),
    startedShift: Ember.computed('shifts.@each.state', function () {
      return this.get('shifts').findBy('state', 'started');
    }),
    showQuestions: Ember.computed.readOnly('appConfiguration.truckSettings.questionaire'),
    groupedTruckInventory: Ember.computed('truck_inventories', function () {
      return this.groupBy(this.get('truck_inventories'), 'material_id', ['currentQty']);
    }),
    canStart: Ember.computed('startable', 'startedShift.id', function () {
      if (this.get('startable') && (!this.get('startedShift') || this.get('startedShift.id') === this.get('id'))) {
        return true;
      }

      return false;
    }),
    canFinish: Ember.computed('trips.isFulfilled', 'trips.@each.state', function () {
      var trips = this.get('trips').filter(function (trip) {
        return trip.get('state') !== 'finished';
      });

      return trips.length === 0;
    }),
    tripStops: Ember.computed('trips.isFulfilled', 'isFulfilled', function () {
      var coll = this.get('trips').map(function (trip) {
        return trip.get('trip_stops').toArray();
      });

      return [].concat.apply([], coll);
    }),
    activeTruck: Ember.computed('TruckID', function () {
      return this.store.peekAll('truck').findBy('TruckID', this.get('TruckID'));
    }),
    activeTrailer: Ember.computed('TrailerID', function () {
      return this.store.peekAll('trailer').findBy('TruckID', this.get('TrailerID'));
    }),
    meterType: Ember.computed('TrailerID', 'activeTruck.@each', 'activeTrailer.@each', function () {
      if (Ember.isPresent(this.get('TrailerID')) && Ember.isPresent(this.get('activeTrailer.MeterTypeID'))) {
        return this.get('activeTrailer.MeterTypeID');
      }

      if (Ember.isPresent(this.get('TruckID')) && Ember.isPresent(this.get('activeTruck.MeterTypeID'))) {
        return this.get('activeTruck.MeterTypeID');
      }

      return null;
    }),

    meterTypeForBM: Ember.computed('meterType', function () {
      return { SENING: 'SeningEMIS', HAAR: 'AlfonsHaar' }[this.get('meterType')];
    }),

    truckInventoryMaterials: function truckInventoryMaterials() {
      return Ember.A(this.get('truck_inventories')).reject(function (truck_inventory) {
        return truck_inventory.get('material_id') === null;
      }).map(function (truck_inventory) {
        return truck_inventory.get('material_id');
      }).uniq();
    },
    getMaxTripSequenceNb: function getMaxTripSequenceNb() {
      return Math.max.apply(null, this.get('trips').map(function (trip) {
        return trip.get('ConfirmedSequenceNb') !== undefined ? trip.get('ConfirmedSequenceNb') : -1;
      }));
    },
    start: function start() {
      this.set('ConfirmedStartDT', new Date());
      this.set('Status', '2');
      this.set('state', 'started');
      return this.save();
    },
    finish: function finish() {
      this.set('ConfirmedEndDT', new Date());
      this.set('state', 'finished');
      return this.save();
    },
    cancel: function cancel() {
      this.set('ConfirmedStartDT', null);
      this.set('state', 'planned');
      this.set('Status', '1');
      return this.save();
    }
  });
});