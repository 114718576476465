define('bottomline-execution/models/truck-setting', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    // Tijmen: Please initialize all default values to false.
    add_extra_order: attr('boolean', { defaultValue: false }),
    add_extra_trip_stops: attr('boolean', { defaultValue: false }),
    material_transfer: attr('boolean', { defaultValue: false }),
    show_atg_inventory_warnings: attr('boolean', { defaultValue: false }),
    compartments: attr('boolean', { defaultValue: false }),
    load_by_weight: attr('boolean', { defaultValue: false }),
    questionaire: attr('boolean', { defaultValue: false }),
    add_special_activity: attr('boolean', { defaultValue: false }),
    authorize_trip_stop: attr('boolean', { defaultValue: false }),
    on_load_mandatory_picture: attr('boolean', { defaultValue: false }),
    on_delivery_mandatory_picture: attr('boolean', { defaultValue: false }),
    on_end_inventory_mandatory_picture: attr('boolean', { defaultValue: false }),
    on_load_capture: attr('boolean', { defaultValue: false }),
    on_delivery_capture: attr('boolean', { defaultValue: false }),
    on_end_inventory_capture: attr('boolean', { defaultValue: false }),
    on_load_scan: attr('boolean', { defaultValue: false }),
    on_delivery_scan: attr('boolean', { defaultValue: false }),
    on_end_inventory_scan: attr('boolean', { defaultValue: false }),
    inventory_in_percentage: attr('boolean', { defaultValue: false }),
    sening_enabled: attr('boolean', { defaultValue: false }),
    terminal_import_enabled: attr('boolean', { defaultValue: false }),
    odometer_on_shift_level: attr('boolean', { defaultValue: false }),
    odometer_on_trip_level: attr('boolean', { defaultValue: false }),
    load_ticketnb_per_tank: attr('boolean', { defaultValue: false }),
    delivery_ticketnb_per_tank: attr('boolean', { defaultValue: false }),
    show_nesenta_link: attr('boolean', { defaultValue: false }),
    register_tank_coordinates: attr('boolean', { defaultValue: false }),
    register_site_coordinates: attr('boolean', { defaultValue: false }),
    show_trip_id_in_trip_overview: attr('boolean', { defaultValue: false }),
    use_access_point: attr('boolean', { defaultValue: false }),
    hide_delivery_ticket_input: attr('boolean', { defaultValue: false }),
    use_material_calculation: attr('boolean', { defaultValue: false }),
    show_atg_information: attr('boolean', { defaultValue: false }),
    adr_report: attr('boolean', { defaultValue: false }),
    dip_unplanned_storages: attr('boolean', { defaultValue: false }),
    truck_compartments_order_desc: attr('boolean', { defaultValue: false }),
    delivery_ticket_filename: attr('string', { defaultValue: '' }),
    delivery_ticket_template: attr('string', { defaultValue: '' }),
    jsreport_url: attr('string', { defaultValue: '' }),
    jsreport_username: attr('string', { defaultValue: '' }),
    jsreport_password: attr('string', { defaultValue: '' }),
    jsreport_via_backend: attr('boolean', { defaultValue: false }),
    meter_coupling_active: attr('boolean', { defaultValue: false }),
    change_terminal: attr('boolean', { defaultValue: false }),
    load_as_planned_enabled: attr('boolean', { defaultValue: false }),
    tank_is_full_enabled: attr('boolean', { defaultValue: false }),
    drop_down_count_enabled: attr('boolean', { defaultValue: false }),
    barrel_count_enabled: attr('boolean', { defaultValue: false }),
    temp_density_ltr15_delivery_enabled: attr('string', { defaultValue: false }), // EP-416: false, 'open', 'closed'
    temp_density_ltr15_load_enabled: attr('string', { defaultValue: false }), // EP-416: false, 'open', 'closed'
    default_compartment_filter_strategy: attr('string', { defaultValue: 'Material ID' }),
    hide_planned_qty_on_delivery: attr('boolean', { defaultValue: false }),
    show_planned_materials: attr('boolean', { defaultValue: false }),
    derive_delivery_tank_15qty: attr('boolean', { defaultValue: false }),
    restrict_delivery_tickets_for_customer_type: attr('string', { defaultValue: false }), // BP-5838
    customer_type_with_flow_meter: attr('string', { defaultValue: false }), // BP-5838
    customer_type_to_filter_orders: attr('string', { defaultValue: false }) // BP-5838
  });
});