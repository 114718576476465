define('bottomline-execution/components/trip-stops', ['exports', 'bottomline-execution/config/environment', 'bottomline-execution/mixins/picture-browser'], function (exports, _environment, _pictureBrowser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_pictureBrowser.default, {
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    androidIntentsAtx: Ember.inject.service(),
    jsreport: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    truckSettings: Ember.computed.alias('appConfiguration.truckSettings'),
    tsMarker: null,
    printTicketsStopIds: Ember.A([]),

    anyStartedStops: Ember.computed('model.@each.state', function () {
      return !!this.get('model').findBy('state', 'started');
    }),

    useBackendForReport: Ember.computed(function () {
      return this.get('appState.truckSettings.jsreport_via_backend');
    }),

    navigationStarted: function navigationStarted() {
      console.log('Navigation started');
    },
    navigationCantStart: function navigationCantStart(e) {
      console.log('Cannot start navigation' + e);
    },
    isBartec: function isBartec(trip) {
      var shift = trip.get('shift');
      var meter = shift.get('activeTrailer.MeterTypeID') || shift.get('activeTruck.MeterTypeID');

      return meter === 'BARTEC';
    },
    incrementTicketNumbersForStop: function incrementTicketNumbersForStop(ticketAmount) {
      return this.get('ajax').request('/last_ticket_number/' + ticketAmount);
    },
    _startNavigation: function _startNavigation(tripStop) {
      launchnavigator.navigate([tripStop.get('latitude'), tripStop.get('longitude')], Ember.run.bind(this, this.navigationStarted), Ember.run.bind(this, this.navigationCantStart));
    },
    _selectTripStop: function _selectTripStop(tripStop) {
      this.get('appState').setSelectedTripStop(tripStop.id);
    },
    _transitionToTripStop: function _transitionToTripStop(tripStop) {
      var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'index';

      this.get('tripsIndexController').transitionToRoute('trip-stops.show.' + page, tripStop.get('TripID'), tripStop.id);
    },


    actions: {
      select: function select(model) {
        this._selectTripStop(model);
      },
      updateState: function updateState(action, model) {
        this._selectTripStop(model);

        model.set('state', action.state);

        if (action.state === 'started') {
          if (_environment.default.APP.target === 'atx') {
            this.get('androidIntentsAtx').start(model.get('ActivityCode'));
          }

          model.start();

          if (model.get('isUnload') && this.get('truckSettings.authorize_trip_stop')) {
            this._transitionToTripStop(model, 'authorize-site');
            return false;
          }

          this._transitionToTripStop(model);
        }
      },
      editStop: function editStop(model) {
        this._selectTripStop(model);
        this._transitionToTripStop(model);
      },
      startNavigation: function startNavigation(tripStop) {
        this._startNavigation(tripStop);
      },
      getDeliveryTicketsFromTrip: function getDeliveryTicketsFromTrip() {
        //TODO: should get delivery tickets to get one report of all trip stops
        // Actually this action shouldn't be here
        // Also the action for getDeliveryTickets can live somewhere outside this component and passed on to this one.
      },
      getDeliveryTicketsFromStop: function getDeliveryTicketsFromStop(stop) {
        var _this = this;

        this.get('printTicketsStopIds').pushObject(stop.get('id'));
        var reportTemplateName = this.get('appConfiguration.truckSettings.delivery_ticket_template');
        var reportFileName = this.get('appConfiguration.truckSettings.delivery_ticket_filename');
        var ticketAmount = this.isBartec(stop.get('trip')) ? 1 : stop.get('trip_stop_storages').length;

        this.incrementTicketNumbersForStop(ticketAmount).then(function (lastTicketNumber) {
          var ticketAmount = _this.isBartec(stop.get('trip')) ? 1 : stop.get('trip_stop_storages').length;
          var ticketNumberObjects = Array.from({ length: ticketAmount }).map(function (_element, index) {
            return { tripStopStorageId: lastTicketNumber - index };
          });

          var reportData = {
            tripId: stop.get('TripID') || '',
            truckId: stop.get('trip.shift.TruckID') || '',
            trailerId: stop.get('trip.shift.TrailerID') || '',
            truckLicense: stop.get('trip.shift.TruckLicense') || '',
            trailerLicense: stop.get('trip.shift.TrailerLicense') || '',
            resourceId: stop.get('trip.shift.ResourceID') || '',
            resourceName: stop.get('trip.shift.ResourceName') || '',
            tripStops: [{
              tripStopID: stop.get('id') || '',
              extOrderId: stop.get('ExtOrderID') || '',
              siteId: stop.get('SiteID') || '',
              extSiteId: stop.get('site.ExtSiteID') || '',
              siteName: stop.get('site.Name') || '',
              siteAddress: stop.get('site.Address') || '',
              siteZipCode: stop.get('site.ZipCode') || '',
              siteCity: stop.get('site.City') || '',
              siteCountry: stop.get('site.CountryCode') || '',
              ownerId: stop.get('site.CountryCode') || '',
              tripStopStorages: ticketNumberObjects
            }]
          };

          _this.get('jsreport').postRequest(reportTemplateName, reportFileName, reportData).finally(function () {
            Ember.run.later(function () {
              _this.get('printTicketsStopIds').removeObject(stop.get('id'));
            }, 2000);
          });
        }).catch(function () {
          _this.get('printTicketsStopIds').removeObject(stop.get('id'));
          _this.get('toast').error('Cannot generate a ticket number. Check your internet connection.');
        });
      }
    }
  });
});