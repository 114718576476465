define('bottomline-execution/models/truck', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    coalesceFindRequests: false,

    TruckID: attr('string'),
    License: attr('string'),
    HaulierID: attr('string'),
    MeterTypeID: attr('string'),
    VehicleType: attr('number'),
    haulier_description: attr('string'),

    truck_setting: belongsTo('truck-setting', { async: false }),
    truck_inventories: hasMany('truck-inventory')
  });
});