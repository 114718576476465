define('bottomline-execution/controllers/settings', ['exports', 'bottomline-execution/utils/url-utils', '@sentry/browser'], function (exports, _urlUtils, _browser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    availableScanners: Ember.A([]),
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    internetConnection: Ember.inject.service(),
    toast: Ember.inject.service(),
    configurationAjax: Ember.inject.service(),

    isPotentialXSS: function isPotentialXSS() {
      var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

      if (!str) return false;

      // Regex to catch XSS
      var regExp = new RegExp(/(\b)(on\S+)(\s*)=|javascript|(<\s*)(\/*)script/, 'ig');

      var matches = str.match(regExp);

      return matches && matches.length;
    },
    _getDomainForAlias: function _getDomainForAlias(alias) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('f7').showPreloader();
        _this.get('configurationAjax').getTabletConfiguration(alias).then(function (response) {
          if (response && response.customer_domain) {
            resolve(response.customer_domain);
          } else {
            reject(_this.get('i18n').t('invalid_alias_or_domain'));
          }
        }, function (_error) {
          reject(_this.get('i18n').t('configuration_service_error'));
        }).finally(function () {
          _this.get('f7').hidePreloader();
        });
      });
    },


    actions: {
      changeSettings: function changeSettings() {
        var _this2 = this;

        if (this.isPotentialXSS(this.get('model').get('scanner_ssid'))) {
          this.get('f7').alert('Incorrect value', this.get('i18n').t('scanner_ssid'));
          return;
        }

        this.set('errorMessage', null);
        this._getDomainForAlias(this.get('model.alias')).then(function (domain) {
          _browser.setTag('alias', _this2.get('model.alias'));

          _this2.set('model.domain', _urlUtils.default.stripTrailingSlash(domain));

          _this2.get('model').save();
          _this2.get('toast').info("Settings saved");

          _this2.transitionToRoute('login');
        }, function (error) {
          _this2.set('errorMessage', error);
        });
      },
      cancel: function cancel() {
        this.get('model').rollbackAttributes();
        this.get('toast').info("Change settings cancelled");
        this.transitionToRoute('login');
      }
    }
  });
});