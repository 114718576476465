define('bottomline-execution/components/trip-state-updater', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    action: null,
    isDisabled: false,
    allTrips: Ember.computed.alias('trip.shift.trips'),
    nextEvents: Ember.computed('trip.state',
    // 'trip.trip_stops.isFulFilled',
    function () {
      var _this = this;

      var events = Ember.A(this.get('trip.store.shiftMeta').tripEvents);

      return events.filter(function (event) {
        return Ember.A(event.options.from).includes(_this.get('trip.state'));
      });
    }),
    nextStates: Ember.computed('trip.state', 'trip.shift.trips.[]',
    // 'trip.trip_stops.isFulFilled',
    function () {
      var _this2 = this;

      var nextStates = this.get('nextEvents').map(function (event) {
        var stateObject = Ember.Object.create();
        var state = { action: event.name, state: event.options.to, disabled: false, isHidden: false };

        if (state.action === 'start' || state.action === 'restart') {
          state.disabled = !_this2.canStartTrip();
        }
        if (state.action === 'finish') {
          state.disabled = !_this2.canStopTrip();

          if (state.disabled && !_this2.get('trip.hasStartedTripStops')) {
            state.isHidden = true;
          }
        }
        if (state.action === 'cancel' && _this2.get('trip.hasStartedTripStops')) {
          state.isHidden = true;
        }

        stateObject.setProperties(state);

        return stateObject;
      });

      return nextStates;
    }),

    canStartTrip: function canStartTrip() {
      var _this3 = this;

      if (!this.get('trip.shift.trips').toArray().length) {
        return false;
      }
      var startedTrip = this.get('trip.shift.trips').toArray().find(function (trip) {
        return trip.get('state') === 'started' && trip.id !== _this3.id;
      });

      return !Ember.isPresent(startedTrip);
    },
    canStopTrip: function canStopTrip() {
      var startedItem = this.get('trip.trip_stops').toArray().find(function (item) {
        return item.get('state') !== 'finished' && Number(item.get('SequenceNb')) !== 999;
      });

      var tripStopsWithUnloadedPictures = this.get('trip.trip_stops').toArray().filter(function (tripStop) {
        return tripStop.get('hasUnloadedPictures');
      });

      return !startedItem && !tripStopsWithUnloadedPictures.length;
    },
    notifyAllTrips: function notifyAllTrips() {
      var _this4 = this;

      this.get('allTrips').reject(function (trip) {
        return trip === _this4.get('trip');
      }).forEach(function (trip) {
        trip.notifyPropertyChange('state');
      });
    },
    showTrip: function showTrip(trip) {
      trip.set('isShown', true);
    },


    actions: {
      start: function start(state, model) {
        this.get('update-state')(state, model);
        this.notifyAllTrips();
        this.showTrip(model);
      },
      cancel: function cancel(state, model) {
        this.get('update-state')(state, model);
        this.notifyAllTrips();
      },
      restart: function restart(state, model) {
        this.get('update-state')(state, model);
        this.notifyAllTrips();
      },
      finish: function finish(state, model) {
        this.get('update-state')(state, model);
        this.notifyAllTrips();
      }
    }
  });
});