define('bottomline-execution/services/jsreport', ['exports', 'bottomline-execution/utils/data-uri-to-blob-util'], function (exports, _dataUriToBlobUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    cordovaPlatform: Ember.inject.service('ember-cordova/platform'),
    ajax: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    truckSettings: Ember.computed.alias('appConfiguration.truckSettings'),

    postRequest: function postRequest(reportTemplateName, reportFileName, reportData) {
      var jsreportsUrl = this.get('truckSettings.jsreport_url');
      var jsreportsUser = this.get('truckSettings.jsreport_username');
      var jsreportsPassword = this.get('truckSettings.jsreport_password');

      if (this.get('truckSettings.jsreport_via_backend')) {
        // send data to the back-end reports api and then it will be sent from backe-end to the jsreports server
        return this._backendApiRequest(reportTemplateName, reportFileName, reportData);
      } else {
        // send data to the jsreports server directly
        return this._jsreportsApiRequest(reportTemplateName, reportFileName, reportData, jsreportsUrl, jsreportsUser, jsreportsPassword);
      }
    },
    _jsreportsApiRequest: function _jsreportsApiRequest(reportTemplateName, reportFileName, reportData, jsreportsUrl, jsreportsUser, jsreportsPassword) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var http = new XMLHttpRequest();
        var options = { timeout: '60000', 'Content-Disposition': 'attachment; filename=' + reportFileName };
        var authBytes = _this.stringToByteArray(jsreportsUser + ':' + jsreportsPassword);
        var authString = _this.byteArrayToBase64(authBytes);
        var reportObject = { template: { name: reportTemplateName }, data: reportData, options: options };
        var params = JSON.stringify(reportObject);

        http.open('POST', jsreportsUrl, true);

        http.setRequestHeader('Authorization', 'Basic ' + authString);
        http.setRequestHeader('Accept', '*/*');
        http.setRequestHeader('Access-Control-Allow-Origin', '*');
        http.setRequestHeader('Content-type', 'application/json');
        http.responseType = 'arraybuffer';

        http.onreadystatechange = function () {
          if (http.readyState === 4 && http.status === 200) {
            var blob = new Blob([http.response], { type: 'application/pdf' });
            _this.createFile(blob, reportFileName);

            resolve(blob);
          }

          // e.g. 404 status code is not handled by onerror event
          if (http.status !== 200) {
            reject(new Error('Status code: ' + http.status));
          }
        };

        http.send(params);
      });
    },
    _backendApiRequest: function _backendApiRequest(reportTemplateName, reportFileName, reportData) {
      var _this2 = this;

      var request = this.get('ajax').post('/reports', {
        data: JSON.stringify({
          template_name: reportTemplateName,
          file_name: reportFileName,
          data: reportData
        })
      });

      return request.then(function (response) {
        var blob = (0, _dataUriToBlobUtil.dataURItoBlobUtil)(response.file);
        _this2.createFile(blob, reportFileName);
        return blob;
      });
    },
    createFile: function createFile(blob, fileName) {
      var _this3 = this;

      var fileReader = new FileReader(); // reads the given blobresult

      fileReader.onloadend = function (e) {
        if (_this3.get('cordovaPlatform.isCordova')) {
          cordova.plugins.fileOpener2.openBase64(fileName.split('.')[0], '.pdf', e.currentTarget.result.split('base64')[1], 'application/pdf');
        } else {
          var windowUrl = window.URL || window.webkitURL;
          var link = document.createElement('a');
          link.href = windowUrl.createObjectURL(blob);
          link.download = fileName;
          link.click();
          windowUrl.revokeObjectURL(link);
        }
      };

      fileReader.readAsDataURL(blob);
    },
    stringToByteArray: function stringToByteArray(text) {
      var data = [];
      for (var i = 0; i < text.length; i++) {
        data.push(text.charCodeAt(i));
      }
      return data;
    },
    byteArrayToBase64: function byteArrayToBase64(byteArray) {
      var bytes = new Uint8Array(byteArray);
      var binary = '';
      for (var i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      return window.btoa(binary);
    }
  });
});