define('bottomline-execution/models/truck-inventory', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    authorizerInitials: '',
    startQty: '',
    tankReference: '',
    byConfirmedStartDT: ['ConfirmedStartDT:desc'],
    byConfirmedEndDT: ['ConfirmedEndDT:desc'],

    truck_storage_id: attr('number'),
    material_id: attr('string'),
    truck_id: attr('string'),
    maxQty: attr('number'),
    currentQty: attr('number'),
    minQty: attr('number'),
    isSigned: attr('boolean', { defaultValue: false }),

    startedTripsSorted: Ember.computed.sort('startedTrips', 'byConfirmedStartDT'),
    finishedTripsSorted: Ember.computed.sort('finishedTrips', 'byConfirmedEndDT'),
    material: Ember.computed('material_id', 'isLoaded', function () {
      if (this.get('material_id')) {
        return this.store.peekRecord('material', this.get('material_id'));
      }
    }),
    materialAdr: Ember.computed('material_id', function () {
      return this.store.peekRecord('adr-material', this.get('material_id'));
    }),
    TruckStorageID: Ember.computed.alias('truck_storage_id'),
    truckStorage: Ember.computed('truck_storage_id', function () {
      return this.store.peekRecord('truck-storage', this.get('truck_storage_id'));
    }),
    startedTrips: Ember.computed(function () {
      return this.store.peekAll('trip').filter(function (item) {
        return item.get('state') === 'started';
      });
    }),
    finishedTrips: Ember.computed(function () {
      return this.store.peekAll('trip').filter(function (item) {
        return item.get('state') === 'finished';
      });
    }),
    current15Qty: Ember.computed('currentQty', function () {
      var _this = this;

      var qty15 = this.get('currentQty');
      var degreeFactor = 1.0;
      var trip = this.get('lastLoadTrip');

      if (trip) {
        trip.get('trip_stops').filterBy('ActivityCode', 'L').forEach(function (tripStop) {
          tripStop.get('trip_stop_truck_storages').filterBy('TruckStorageID', _this.get('truck_storage_id')).forEach(function (tripStopTruckStorage) {
            if (tripStopTruckStorage.get('confirmedQty') !== null && tripStopTruckStorage.get('confirmed15Qty') !== null && tripStopTruckStorage.get('confirmedQty') !== 0) {
              degreeFactor = tripStopTruckStorage.get('confirmed15Qty') / tripStopTruckStorage.get('confirmedQty');
            }
          });
        });
      }

      if (degreeFactor !== 1.0) {
        qty15 = this.get('currentQty') * degreeFactor;
      }

      return Math.round(qty15);
    }),
    lastLoadTrip: Ember.computed(function () {
      if (this.get('startedTripsSorted').length > 0) {
        return this.get('startedTripsSorted')[0];
      } else if (this.get('finishedTripsSorted').length > 0) {
        return this.get('finishedTripsSorted')[0];
      }
    })
  });
});