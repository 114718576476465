define('bottomline-execution/components/trip-stop-truck-storage-unload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    truckSettings: Ember.computed.alias('appConfiguration.truckSettings'),

    classNames: 'storage-compartment',
    tagName: 'td',
    classNameBindings: ['isHighlightedCompartment', 'isHiddenCompartment'],

    isHighlightedCompartment: Ember.computed.alias('filterStrategy'),
    isHiddenCompartment: Ember.computed.not('showCompartment'),
    showCompartment: Ember.computed('showAllCompartments', 'tripStopStorage', function () {
      if (this.get('showAllCompartments')) {
        return true;
      } else {
        return this.get('filterStrategy');
      }
    }),
    filterStrategy: Ember.computed('defaultFilterStrategy', 'tripStopStorage', function () {
      var defaultFilterStrategy = this.get('defaultFilterStrategy').toLowerCase();
      var site = this.get('site');

      if (defaultFilterStrategy === 'material id' || site.get('useFlowMeter')) {
        return this.get('filterOnMaterialID');
      } else if (defaultFilterStrategy === 'planned tank') {
        return this.get('filterOnPlannedTank');
      }
    }),
    defaultFilterStrategy: Ember.computed.alias('truckSettings.default_compartment_filter_strategy'),
    filterOnMaterialID: Ember.computed.alias('materialSameAsInSelectedTripStopStorage'),
    filterOnPlannedTank: Ember.computed('belongsToSelectedTripStopStorage', 'materialSameAsInSelectedTripStopStorage', function () {
      return this.get('belongsToSelectedTripStopStorage') && this.get('materialSameAsInSelectedTripStopStorage');
    }),
    belongsToSelectedTripStopStorage: Ember.computed('tripStopStorage.StorageID', 'tripStopTruckStorage.StorageID', function () {
      return Number(this.get('tripStopStorage.StorageID')) === Number(this.get('tripStopTruckStorage.StorageID'));
    }),
    hasPlannedQuantity: Ember.computed('tripStopTruckStorage.plannedQty', function () {
      return this.get('tripStopTruckStorage.plannedQty') > 0;
    }),
    isPlannedMaterial: Ember.computed.alias('tripStopTruckStorage.hasPlannedMaterial'),
    site: Ember.computed.alias('trip-stop.site'),
    showPlannedQuantity: Ember.computed('isPlannedMaterial', 'truckSettings.hide_planned_qty_on_delivery', function () {
      return this.get('isPlannedMaterial') && !this.get('truckSettings.hide_planned_qty_on_delivery');
    }),
    showLock: Ember.computed.not('materialSameAsInSelectedTripStopStorage'),
    materialSameAsInSelectedTripStopStorage: Ember.computed('tripStopStorage.MaterialID', 'truckStorage.material_id', function () {
      return this.get('tripStopStorage.MaterialID') === this.get('truckStorage.material_id');
    }),
    isLocked: Ember.computed('lock', function () {
      if (this.get('lock') === null) {
        this.set('lock', this.get('defaultLockSetting'));
      }
      return this.get('lock');
    }),
    lock: null,
    defaultLockSetting: Ember.computed.not('materialSameAsInSelectedTripStopStorage'),

    allTripStopTruckStorages: Ember.computed('trip-stop', 'tripStopTruckStorage', function () {
      var _this = this;

      var items = this.get('trip-stop.store').peekAll('trip-stop-truck-storage').filter(function (tsts) {
        return Number(tsts.get('TruckStorageID')) === Number(_this.get('truckStorage.TruckStorageID')) && Number(tsts.get('confirmedQty')) !== 0 && tsts !== _this.get('tripStopTruckStorage') && Number(_this.get('trip-stop.TripID')) === Number(tsts.get('TripID'));
      });

      items.push(this.get('tripStopTruckStorage'));

      return items;
    }),
    tripStopTruckStorages: Ember.computed('trip-stop', function () {
      var _this2 = this;

      return this.get('trip-stop.trip_stop_truck_storages').filter(Ember.run.bind(this, function (tsts) {
        return Number(tsts.get('TruckStorageID')) === Number(_this2.get('truckStorage.TruckStorageID')) &&
        // Number(tsts.get('StorageID')) !== Number(this.get('tripStopStorage.StorageID')) &&
        Number(tsts.get('hasConfirmedMaterial')) && tsts.get('TruckStorageID') > 0;
      }));
    }),
    tripStopTruckStorage: Ember.computed('tripStopStorage', function () {
      var _this3 = this;

      var item = this.get('trip-stop.trip_stop_truck_storages').find(Ember.run.bind(this, function (tsts) {
        return Number(tsts.get('TruckStorageID')) === Number(_this3.get('truckStorage.TruckStorageID'));
      }));

      if (Ember.isEmpty(item)) {
        var truckInventory = this.get('trip-stop.trip.shift.truck_inventories').findBy('truck_storage_id', this.get('truckStorage.TruckStorageID'));

        item = this.get('trip-stop.trip_stop_truck_storages').createRecord({
          id: this.get('trip-stop').createGuid('from truck-storage-load'),
          plannedQty: null,
          confirmedQty: null,
          TripStopID: this.get('trip-stop.id'),
          TripID: this.get('trip-stop.TripID'),
          TruckID: truckInventory.get('truckStorage.truck_id'),
          TruckStorageID: this.get('truckStorage.TruckStorageID'),
          ActivityCode: this.get('trip-stop.ActivityCode'),

          MaterialID: null,
          StorageID: null
        });
      }

      return item;
    }),
    totalConfirmedQty: Ember.computed('allTripStopTruckStorages.@each.confirmedQty', function () {
      return this.get('allTripStopTruckStorages').map(function (item) {
        if (item.get('trip_stop.isUnload')) {
          return -1 * item.get('confirmedQty');
        } else {
          return item.get('confirmedQty');
        }
      }).reduce(function (previousValue, currentValue) {
        return Number(previousValue) + Number(currentValue);
      }, 0);
    }),

    tripStopStorageChanged: Ember.observer('tripStopStorage', function () {
      this.set('lock', null);
    }),

    actions: {
      toggleLock: function toggleLock() {
        this.toggleProperty('lock');
      },
      confirmQty: function confirmQty(qty) {
        qty ? this.get('tripStopTruckStorage').set('confirmedQty', qty) : null;
        this.get('tripStopTruckStorage').set('MaterialID', this.get('tripStopStorage.MaterialID'));
        this.get('tripStopTruckStorage').set('StorageID', this.get('tripStopStorage.StorageID'));

        this.get('tripStopStorage').updateConfirmedQty();
      },
      fillQty: function fillQty() {
        var confirmed = Number(this.get('tripStopStorage.confirmedQty'));
        var total = Number(this.get('totalConfirmedQty'));
        var result = 0;

        if (total - confirmed >= 0) {
          result = confirmed;
        } else {
          result = confirmed - total;
        }

        this.set('tripStopTruckStorage.confirmedQty', result);

        this.get('tripStopStorage').calculateTotalCompartmentQty();
      }
    }
  });
});