define('bottomline-execution/routes/shifts/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'bottomline-execution/mixins/storages-loader'], function (exports, _authenticatedRouteMixin, _storagesLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _storagesLoader.default, {
    queryParams: { truckId: { refreshModel: true } },
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),

    model: function model() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        _this._clearTruckInventory();

        _this.get('store').query('shift', { truck: _this.get('appState.currentTruck') }).then(function (shifts) {
          _this._loadRecentTripStop(shifts);

          _this._loadShiftsData(shifts).then(function () {
            _this.loadAllSiteStorages();
          });
          _this._ensureSelectedShift(shifts);

          return _this._updateAppState(shifts);
        }).then(function (shifts) {
          resolve(shifts);
        });
      });
    },
    setupController: function setupController(controller, model) {
      this.store.set('shiftMeta', model.get('meta'));
      controller.set('model', model);
    },
    _clearTruckInventory: function _clearTruckInventory() {
      var _this2 = this;

      this.get('store').peekAll('truck-inventory').reject(function (e) {
        return !/ember/.test(e.id) || e.id === null;
      }).forEach(function (rec) {
        return _this2.store.deleteRecord(rec);
      });
    },
    _ensureSelectedShift: function _ensureSelectedShift(shifts) {
      if (Ember.isBlank(this.get('appState.selectedShift'))) {
        this.get('appState').set('selectedShift', shifts.get('firstObject.id'));
      }
    },
    _updateAppState: function _updateAppState(shifts) {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var appState = _this3.get('appState');

        appState.setProperties({
          anyShiftStarted: shifts.filterBy('state', 'started').length > 0,
          currentTrailer: _this3.get('appState.shift.TrailerID') || null
        });

        appState.save().then(function () {
          resolve(shifts);
        });
      });
    },
    _loadRecentTripStop: function _loadRecentTripStop(shifts) {
      var recentLoadTripStopPayload = shifts.get('meta').recentLoadTripStop;
      if (Ember.isPresent(recentLoadTripStopPayload)) {
        var tripStopId = recentLoadTripStopPayload.trip_stop.id;
        return this.store.findRecord('trip-stop', tripStopId);
      }
    },
    _loadShiftsData: function _loadShiftsData(shifts) {
      return Ember.RSVP.Promise.all(shifts.map(function (shift) {
        return shift.get('trips');
      })).then(function (shiftsTrips) {
        return Ember.RSVP.Promise.all(shiftsTrips.flatten().map(function (trip) {
          return trip.get('trip_stops');
        }));
      });
    }
  });
});