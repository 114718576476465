define('bottomline-execution/services/app-configuration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    state: Ember.computed(function () {
      return this.get('store').peekRecord('state', 1);
    }),

    truckSettings: Ember.computed.alias('state.truckSettings')
  });
});