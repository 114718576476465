define('bottomline-execution/mixins/trip-stop-material-on-truck', ['exports', 'bottomline-execution/mixins/group-by'], function (exports, _groupBy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_groupBy.default, {
    scopedData: Ember.inject.service(),
    store: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    useCompartments: Ember.computed.alias('appConfiguration.truckSettings.compartments'),

    tripStopMaterialOnTruck: function tripStopMaterialOnTruck() {
      var _this = this;

      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref$tripStop = _ref.tripStop,
          tripStop = _ref$tripStop === undefined ? null : _ref$tripStop,
          _ref$trip = _ref.trip,
          trip = _ref$trip === undefined ? null : _ref$trip,
          _ref$includeTrailerIn = _ref.includeTrailerInventories,
          includeTrailerInventories = _ref$includeTrailerIn === undefined ? false : _ref$includeTrailerIn,
          _ref$filterZeroCurren = _ref.filterZeroCurrentQty,
          filterZeroCurrentQty = _ref$filterZeroCurren === undefined ? true : _ref$filterZeroCurren,
          _ref$filterZeroNeeded = _ref.filterZeroNeededQty,
          filterZeroNeededQty = _ref$filterZeroNeeded === undefined ? true : _ref$filterZeroNeeded;

      var state = this.get('appState');

      var currentTrip = Ember.isPresent(tripStop) ? tripStop.get('trip') : trip;
      var currentShift = Ember.isPresent(currentTrip) ? currentTrip.get('shift') : this.get('appState.shift');
      var allInventories = currentShift.get('truck_inventories');

      var vehicleIds = includeTrailerInventories ? [state.get('currentTruck'), state.get('currentTrailer')] : [state.get('currentTruck')];

      var targetTruckInventories = [];

      if (this.get('useCompartments')) {
        targetTruckInventories = this.get('scopedData').vehicleInventoryByCompartment(allInventories, vehicleIds, 'truck_storage_id');
      } else {
        targetTruckInventories = this.get('scopedData').truckInventoryByMaterial(allInventories, vehicleIds, 'truck_storage_id');
      }

      var groupedTargetTruckInventories = this.groupBy(targetTruckInventories, 'material_id', ['currentQty']).map(function (item) {
        var itemId = item.group;

        if (itemId) {
          item.set('material', _this.get('store').peekRecord('material', itemId));
        }

        item.set('neededQty', tripStop ? Number(tripStop.tripStopStorageTotalByMaterial(itemId)) : 0);

        return item;
      });

      if (filterZeroCurrentQty) {
        groupedTargetTruckInventories = groupedTargetTruckInventories.filter(function (item) {
          return Number(item.get('totalcurrentQty')) !== 0;
        });
      }

      if (filterZeroNeededQty) {
        groupedTargetTruckInventories = groupedTargetTruckInventories.filter(function (item) {
          return Number(item.get('neededQty') !== 0);
        });
      }

      return groupedTargetTruckInventories;
    }
  });
});