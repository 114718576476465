define('bottomline-execution/models/meter-counter', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    TripStopId: attr('number'),
    MeterId: attr('number'),
    MeterName: attr('string'),
    MeterSerial: attr('string'),
    TotalQty: attr('number'),
    Total15Qty: attr('number'),
    TotalWeight: attr('number'),
    ReportDt: attr('date')
  });
});