define('bottomline-execution/controllers/login', ['exports', 'bottomline-execution/utils/url-utils', 'fetch'], function (exports, _urlUtils, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    customerConfiguration: Ember.inject.service(),
    configurationAjax: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    truckSelector: Ember.inject.service(),
    internetConnection: Ember.inject.service(),
    devise: Ember.computed(function () {
      return Ember.getOwner(this).lookup('authenticator:devise');
    }),
    toast: Ember.inject.service(),

    _getTabletConfiguration: function _getTabletConfiguration(configAlias) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('configurationAjax').getTabletConfiguration(configAlias).then(function (response) {
          if (response && response.customer_domain) {
            var config_alias = response.config_alias,
                customer_domain = response.customer_domain,
                android_logging = response.android_logging;


            var domain = _urlUtils.default.stripTrailingSlash(customer_domain);

            _this.get('appState').set('domain', domain);
            _this.get('appState').set('alias', config_alias);
            _this.get('appState').set('android_logging', android_logging);
            _this.get('appState').save();
          } else if (configAlias) {
            reject('invalid_alias_or_domain');
          }
          resolve('ok');
        }, function (_error) {
          // Enable to continue with locally cached values
          resolve('use_cached_configuration');
        });
      });
    },
    _ensureDomain: function _ensureDomain() {
      if (Ember.isBlank(this.get('appState.domain'))) {
        this.get('appState').set('domain', window.location.origin);
      }
    },
    _authenticate: function _authenticate(userName, password) {
      var _this2 = this;

      // Configure Devise to reject the promise in a way that allows
      // showing an error message when the domain is not set in the app
      this.get('devise').set('rejectWithResponse', true);

      this.set('password', null);
      this.get('session').authenticate('authenticator:devise', userName, password).then(function () {
        _this2.get('truckSelector').resetCurrentTruck();
        _this2.transitionToRoute('edit-truck');
      }, function (error) {
        var errorMessage = 'unexpected_authentication_error';

        if (error instanceof TypeError) {
          switch (error.message) {
            case 'Network request failed':
              errorMessage = 'domain_unreachable';
              break;
          }
        } else if (error instanceof _fetch.Response) {
          switch (error.status) {
            case 401:
              errorMessage = 'login_failed';
              break;
            case 404:
              errorMessage = 'domain_not_set';
              break;
            default:
              errorMessage = error.status + ' ' + error.statusText;
          }
        }

        _this2.set('errorMessage', _this2.get('i18n').t(errorMessage));
      }).catch(function (error) {
        // display an error when authenticator:devise raises an error
        _this2.set('errorMessage', _this2.get('i18n').t(error));
      });
    },


    actions: {
      updateLanguage: function updateLanguage(value) {
        var state = this.get('model');
        state.set('language', value);
        this.set('i18n.locale', value);
        state.save();
      },
      authenticate: function authenticate() {
        var _this3 = this;

        var _getProperties = this.getProperties('identification', 'password'),
            identification = _getProperties.identification,
            password = _getProperties.password;

        var _identification$split = identification.split('@'),
            _identification$split2 = _slicedToArray(_identification$split, 2),
            userName = _identification$split2[0],
            configAlias = _identification$split2[1];

        this.set('errorMessage', null);
        this.get('f7').showPreloader();

        this._getTabletConfiguration(configAlias).then(function (result) {
          if (result != 'ok') {
            _this3.get('toast').warning(_this3.get('i18n').t(result));
          }

          _this3._ensureDomain();
          _this3._authenticate(userName, password);
        }, function (error) {
          _this3.set('errorMessage', _this3.get('i18n').t(error));
        }).finally(function () {
          _this3.get('f7').hidePreloader();
        });
      }
    }
  });
});