define('bottomline-execution/controllers/trips/index', ['exports', 'bottomline-execution/config/environment', 'bottomline-execution/mixins/trip-sorter'], function (exports, _environment, _tripSorter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_tripSorter.default, {
    i18n: Ember.inject.service(),
    internetConnection: Ember.inject.service(),
    ajax: Ember.inject.service(),
    anyStartedStops: false,
    selectedTrip: null,
    selectedTripStopId: Ember.computed.alias('appState.selectedTripStop'),
    selectedTripStop: Ember.computed('appState.selectedTripStop', function () {
      if (Ember.isPresent(this.get('selectedTripStopId'))) {
        return this.get('store').peekRecord('trip-stop', this.get('selectedTripStopId'));
      } else {
        return null;
      }
    }),
    addActivity: false,
    invertedWidth: false,
    isVisible: true,
    defaultMode: true,
    isOpeningTicket: false,
    isOpeningReport: false,
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    documentsEndpoint: Ember.computed('appState.domain', function () {
      return this.get('appState.domain') + '/' + _environment.default.api + '/documents?';
    }),
    currentTruck: Ember.computed.alias('appState.currentTruck'),
    askForOdometer: Ember.computed.alias('appConfiguration.truckSettings.odometer_on_trip_level'),
    useMaterialCalculation: Ember.computed.alias('appConfiguration.truckSettings.use_material_calculation'),
    showAtgInformation: Ember.computed.alias('appConfiguration.truckSettings.show_atg_information'),
    useBackendForReport: Ember.computed(function () {
      return this.get('appConfiguration.truckSettings.jsreport_via_backend');
    }),
    trips: Ember.computed.alias('model'),
    startedTrip: Ember.computed('model.@each.state', function () {
      return this.get('model').toArray().find(function (trip) {
        return trip.get('state') === 'started';
      });
    }),
    tripIsStarted: Ember.computed('startedTrip', function () {
      return Ember.isPresent(this.get('startedTrip'));
    }),
    selectedTripStopTripIsStarted: Ember.computed('selectedTripStop.trip.state', 'startedTrip', function () {
      if (Ember.isBlank(this.get('selectedTripStop'))) return false;
      return this.get('startedTrip') && this.get('selectedTripStop.trip.state') === 'started';
    }),
    ATGProblemTanksPresent: Ember.computed('selectedTripStop.trip.ATGProblemTSS', function () {
      if (Ember.isBlank(this.get('selectedTripStop'))) return false;
      return Ember.isPresent(this.get('selectedTripStop.trip.ATGProblemTSS'));
    }),
    isATGStop: Ember.computed('selectedTripStop.site.isATGStop', function () {
      if (Ember.isBlank(this.get('selectedTripStop'))) return false;
      return this.get('selectedTripStop.site.isATGStop');
    }),
    remarksForDriverPresent: Ember.computed('selectedTripStop.allRemarks', function () {
      if (Ember.isBlank(this.get('selectedTripStop'))) return false;
      return Ember.isPresent(this.get('selectedTripStop.allRemarks'));
    }),
    selectedMarkers: Ember.computed('selectedTripStop', function () {
      return Ember.A([this.get('selectedTripStop')]);
    }),
    tripMarkers: Ember.computed('selectedTripStop.trip.displayableTripStops.[]', 'selectedTripStop.trip.isFulFilled', function () {

      return this.get('selectedTripStop.trip.displayableTripStops');
    }),
    points: Ember.computed('model', 'model.@each.trip_stops', 'model.isFulfilled', function () {
      var tripStops = [];
      this.get('model').forEach(function (trip) {
        tripStops.concat(trip.get('tripStops'));
      });
      return tripStops;
    }),
    shift: Ember.computed('model.firstObject', function () {
      return this.get('model.firstObject.shift');
    }),
    shiftStateStarted: Ember.computed('model.firstObject.shift.state', function () {
      return this.get('model.firstObject.shift.state') === 'started';
    }),
    currentTruckInventory: Ember.computed('shift', 'currentTruck', function () {
      return this.get('shift.truck_inventories').filterBy('truck_id', this.get('currentTruck'));
    }),
    canChangeTerminal: Ember.computed('startedTrip.hasLoadStop', 'startedTrip.loadStopIsConfirmed', function () {
      return this.get('startedTrip.hasLoadStop') && !this.get('startedTrip.loadStopIsConfirmed');
    }),

    actions: {
      toggleShowTrip: function toggleShowTrip(trip) {
        trip.toggleProperty('isShown');
      },
      toggleInvertedWidth: function toggleInvertedWidth(val) {
        this.set('invertedWidth', val);
      },
      addSpecialActivity: function addSpecialActivity() {
        this.toggleProperty('addActivity');
      },
      updateState: function updateState(action, model) {
        var _this = this;

        this.get('appState').setSelectedTrip(model.id);
        switch (action.state) {
          case 'started':
            // Start and Restart
            model.reload().then(function () {

              if (action.action === 'restart' && model.get('hasSkippedTripStops')) {
                model.get('tripStops').forEach(function (tripStop) {
                  return tripStop.set('DriverRemarks', null);
                });
                model.set('ConfirmedEndDT', null);
              }

              _this._askForOdometerStart(model).then(function () {
                // trip state change to 'started' is performed at the end of the start task
                if (action.state !== 'started') {
                  model.set('state', action.state);
                }

                model.start();
                model.get('shift.trips').forEach(function (trip) {
                  return trip.notifyPropertyChange('state');
                });
              });
            });
            break;
          case 'finished':
            // Finish
            this._askForOdometerEnd(model).then(function () {
              model.set('state', action.state);
              model.finish();
            });

            break;
          case 'planned':
            // Cancel
            model.set('state', action.state);
            model.cancel();
        }
      },
      viewTicket: function viewTicket(trip) {
        var url = this.get('documentsEndpoint') + trip.get('ticketUrl', '_blank');

        this.openPDF(url, 'deliveryplus_ticket_' + trip.id + '.pdf', 'isOpeningTicket');
      },
      viewReport: function viewReport(trip) {
        var url = this.get('documentsEndpoint') + trip.get('reportUrl', '_blank');

        this.openPDF(url, 'deliveryplus_report_' + trip.id + '.pdf', 'isOpeningReport');
      },
      viewRemarks: function viewRemarks(trip) {
        this.set('selectedTrip', trip);
        this.set('defaultMode', false);
      },
      closeRemarks: function closeRemarks() {
        this.set('defaultMode', true);
      },
      skipTrip: function skipTrip(trip) {
        trip.get('tripStops').forEach(function (tripStop) {
          return tripStop.set('DriverRemarks', 'Skipped');
        });
        trip.set('state', 'finished');
        trip.finish();
      },
      syncTrip: function syncTrip(trip) {
        var tripId = trip && trip.id;

        if (!tripId || !trip.get('isShown')) {
          return;
        }

        trip.set('beingProcessed', true);

        this.get('store').findRecord('trip', tripId, { reload: true }).finally(function () {
          trip.set('beingProcessed', false);
        });
      }
    },

    getAllTripStops: function getAllTripStops() {
      var _this2 = this;

      return this.get('store').peekAll('trip-stop').filter(function (tripStop) {
        return tripStop.get('tripID') === _this2.get('trip').id;
      });
    },
    openPDF: function openPDF(url, fileName) {
      var _this3 = this;

      var isOpeningFlagProperty = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      if (isOpeningFlagProperty) this.set(isOpeningFlagProperty, true);
      var fileTransfer = new FileTransfer();
      var filePath = cordova.file.externalDataDirectory + fileName;

      fileTransfer.download(encodeURI(url), filePath, function (fileEntry) {
        Ember.run.later(function () {
          if (isOpeningFlagProperty) _this3.set(isOpeningFlagProperty, false);
        }, 2000);
        cordova.plugins.fileOpener2.open(fileEntry.toInternalURL(), 'application/pdf');
      }, function (error) {
        if (isOpeningFlagProperty) _this3.set(isOpeningFlagProperty, false);
        _this3.get('f7').alert('Cannot open file (' + error.http_status + ')', 'Warning code: ' + error.code);
        $(".navbar").show();
      }, null, {
        headers: this.get('ajax.headers')
      });
    },
    _askForOdometerEnd: function _askForOdometerEnd(trip) {
      var deferred = Ember.RSVP.defer();

      if (!this.get('askForOdometer')) {
        deferred.resolve();
        return deferred.promise;
      }

      var odometer = trip.get('OdometerEnd');
      this.get('f7').extdPrompt(this.get('i18n').t('end_of_trip'), this.get('i18n').t('form_odometer'), 'number', odometer, function (error) {
        if (error !== 'undefined' && error !== '' && error !== '0') {
          if (error !== odometer) {
            trip.set('OdometerEnd', error);
          }

          deferred.resolve();
        }
      }, function () {
        return deferred.reject();
      });

      return deferred.promise;
    },
    _askForOdometerStart: function _askForOdometerStart(trip) {
      var deferred = Ember.RSVP.defer();

      if (!this.get('askForOdometer')) {
        deferred.resolve();

        return deferred.promise;
      }

      var odometer = trip.get('OdometerStart');

      this.get('f7').extdPrompt(this.get('i18n').t('start_of_trip'), this.get('i18n').t('form_odometer'), 'number', odometer, function (error) {
        if (error !== 'undefined' && error !== '' && error !== '0') {
          if (error !== odometer) {
            trip.set('OdometerStart', error);
          }

          deferred.resolve();
        }
      }, function () {
        return deferred.reject();
      });

      return deferred.promise;
    }
  });
});