define('bottomline-execution/services/remote-logger', ['exports', 'ember-network/fetch', 'bottomline-execution/config/environment'], function (exports, _fetch2, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    host: 'http://bls_logger.qraftz.nl',
    namespace: 'api',
    path: 'applog',

    adapter: Ember.computed(function () {
      return Ember.getOwner(this).lookup('adapter:application');
    }),
    env: Ember.computed(function () {
      return _environment.default;
    }),
    headers: Ember.computed.alias('adapter.headers'),
    url: Ember.computed('host', 'namespace', 'path', function () {
      var host = this.get('host') || '';
      var namespace = this.get('namespace') || '';
      var path = this.get('path');

      function constructUrl(start, parts) {
        if (parts.length === 0) {
          return start;
        }

        var newPart = parts.shift();
        var newStart = void 0;

        if (newPart.length === 0) {
          newStart = start;
        } else {
          newStart = start + '/' + newPart;
        }

        return constructUrl(newStart, parts);
      }

      return constructUrl(host, [namespace, path]);
    }),

    debug: function debug(entry) {
      var tags = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      return this._push('debug', entry, tags);
    },
    info: function info(entry) {
      var tags = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      return this._push('info', entry, tags);
    },
    warn: function warn(entry) {
      var tags = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      return this._push('warn', entry, tags);
    },
    error: function error(entry) {
      var tags = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      return this._push('error', entry, tags);
    },
    logInfo: function logInfo(msg) {
      this.info(msg, [this.get('env.environment'), this.get('env.APP.version'), this._appState().get('currentTruck')]);
    },
    _push: function _push(level, entry) {
      var tags = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

      tags.unshift(level.toUpperCase());

      var formattedEntry = this._formatEntry(entry, tags);

      Ember.get(Ember.Logger, level).call(this, formattedEntry);

      return this._sendRequest(formattedEntry);
    },
    _formatEntry: function _formatEntry(entry) {
      var tags = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      return tags.map(function (t) {
        return ['[', t, ']'].join('');
      }).concat(entry).join(' ');
    },
    _sendRequest: function _sendRequest(entry) {
      var url = this.get('url');
      var headers = this.get('headers');

      if (headers) {
        headers['Content-Type'] = 'text/plain';
      }

      var fetchOptions = {
        method: 'POST',
        headers: headers,
        body: entry
      };

      return this._fetch(url, fetchOptions).then(function (r) {
        if (r.ok) {
          return Ember.RSVP.resolve(r);
        } else {
          return Ember.RSVP.reject(r);
        }
      });
    },
    _appState: function _appState() {
      return this.get('store').peekRecord('state', 1);
    },
    _fetch: function _fetch(url, options) {
      return (0, _fetch2.default)(url, options);
    }
  });
});