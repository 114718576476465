define('bottomline-execution/components/trip-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    locationService: Ember.inject.service('location'),
    assetMap: Ember.inject.service('asset-map'),

    classNames: ['map'],
    'on-trip-stop-click': null,
    points: [],
    markers: [],
    selectedMarkers: [],
    currentLocation: null,
    invertedWidth: false,
    mapScrollable: true,
    center: true,
    zoom: 15,
    fitSelectedEnabled: true,
    markersChanged: Ember.observer('markers.@each.latitude', 'locationService.lat', 'selectedMarkers.[]', function () {
      this.renderPoints();
    }),
    locationChanged: Ember.observer('locationService.lat', function () {
      this.centerMap();
    }),
    sizeChanged: Ember.observer('invertedWidth', function () {
      var map = this.get('gmap').map;

      setTimeout(function () {
        return google.maps.event.trigger(map, 'resize');
      }, 400);
    }),
    currentLocationChanged: Ember.observer('locationService.lat', 'markers.[]', function () {
      if (this.get('locationService.lat')) {
        this.set('currentLocation', this.getLocationMarker());
        this.getLocationMarker().setMap(this.get('gmap').map);
      }
    }),
    selectedTrip: Ember.computed.alias('parentController.selectedTrip'),
    selectedShift: Ember.computed.alias('parentController.selectedShift'),
    map: Ember.computed(function () {
      return this.$();
    }),
    gmap: Ember.computed('selectedTrip', 'invertedWidth', function () {
      var _this = this;

      if (typeof GMaps !== 'undefined' && this.get('map')) {
        return new GMaps({
          el: this.get('map')[0],
          height: '300px',
          lat: this.get('locationService.lat'),
          lng: this.get('locationService.lng'),
          zoom: this.get('zoom'),
          styles: [{ hue: '#00d4ff' }, { saturation: 60 }, { lightness: -20 }, { gamma: 1.51 }],
          resize: function resize() {
            _this.renderPoints();
          }
        });
      }
    }),

    renderMap: Ember.on('didInsertElement', function () {
      var _this2 = this;

      this.get('locationService');
      setTimeout(function () {
        _this2.initCurrentLocation();
        _this2.renderPoints();
        _this2.centerMap();
      }, 2000);
    }),

    renderPoints: function renderPoints() {
      var _this3 = this;

      var selectedMarkers = this.get('selectedMarkers');
      var markers = this.get('markers');
      var gmap = this.get('gmap');

      if (gmap) {
        gmap.removeMarkers();
      }

      if (Ember.isEmpty(markers)) {
        return [];
      }

      var points = markers.reduce(function (result, tripStop) {
        var isSelected = selectedMarkers && selectedMarkers.indexOf(tripStop) > -1;

        if (tripStop.get('latitude') && tripStop.get('trip_stop_storages.length')) {
          tripStop.get('trip_stop_storages').forEach(function (tss) {

            if (tss.get('storage.longitude') && tss.get('storage.longitude')) {
              var showTSSMarker = tripStop.get('latitude') + '' !== tss.get('storage.latitude') + '' || tripStop.get('longitude') + '' !== tss.get('storage.longitude') + '';

              if (showTSSMarker) {
                result.addObject({
                  id: tss.id,
                  lat: tss.get('storage.latitude'),
                  lng: tss.get('storage.longitude'),
                  title: tripStop.get('sitename'),
                  optimized: true,
                  zIndex: isSelected ? 998 : 887,
                  icon: _this3.getIconPath(tripStop, isSelected, true),
                  click: function click() {
                    if (_this3.get('on-trip-stop-click')) {
                      _this3.get('on-trip-stop-click')(tripStop.id);
                      _this3.renderPoints();
                    }
                  }
                });
              }
            }
          });
        }

        result.addObject({
          id: tripStop.id,
          lat: tripStop.get('latitude'),
          lng: tripStop.get('longitude'),
          title: tripStop.get('sitename'),
          optimized: true,
          zIndex: isSelected ? 999 : 888,
          icon: _this3.getIconPath(tripStop, isSelected),
          click: function click() {
            if (_this3.get('on-trip-stop-click')) {
              _this3.get('on-trip-stop-click')(tripStop.id);
              _this3.renderPoints();
            }
          }
        });

        return result;
      }, []).filter(function (marker) {
        return !Ember.isEmpty(marker.lat);
      });

      if (gmap) {
        gmap.addMarkers(points);
        this.centerMap();
        this.fitSelected();
      }
    },
    getIconPath: function getIconPath(tripStop, isSelected) {
      var isTank = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var isDepot = tripStop.get('isLoad') || tripStop.get('isCorrection');
      var iconFileNameParts = isDepot ? ['depot'] : ['fillingstation'];

      isTank && !isDepot ? iconFileNameParts.push('tank') : null;
      isSelected ? iconFileNameParts.push('green') : null;
      tripStop.get('CompletedYN') ? iconFileNameParts.push('completed') : null;

      return this.get('assetMap').resolve('assets/' + iconFileNameParts.join('-') + '.png');
    },
    getLatLng: function getLatLng() {
      return new google.maps.LatLng(this.get('locationService.lat'), this.get('locationService.lng'));
    },
    getLocationMarker: function getLocationMarker() {
      if (Ember.isPresent(this.get('currentLocation'))) {
        this.set('currentLocation.position', this.getLatLng());

        return this.get('currentLocation');
      }

      this.set('currentLocation', new google.maps.Marker({
        position: this.getLatLng(),
        icon: { url: 'assets/truck.png' }
      }));

      return this.get('currentLocation');
    },
    initCurrentLocation: function initCurrentLocation() {
      if (this.get('locationService') && this.get('locationService.lat')) {
        this.getLocationMarker().setMap(this.get('gmap').map);
        this.fitSelected();
      }
    },
    centerMap: function centerMap() {
      if (this.get('center') && this.get('locationService.lat')) {
        this.get('gmap').setCenter(this.get('locationService.lat'), this.get('locationService.lng'));
        this.get('gmap').setZoom(this.get('zoom'));
        this.set('center', false);
      }
    },
    fitSelected: function fitSelected() {
      if (!this.get('fitSelectedEnabled')) {
        return;
      } else if (this.get('selectedMarker') && this.get('locationService.lat')) {
        var bounds = [new google.maps.LatLng(this.get('selectedMarker.latitude'), this.get('selectedMarker.longitude')), new google.maps.LatLng(this.get('locationService.lat'), this.get('locationService.lng'))];

        if (this.get('fitSelectedEnabled') && this.get('markers').toArray().length > 0) {
          this.get('gmap').fitLatLngBounds(bounds);
        }
      } else {
        this.get('gmap').fitZoom();
      }
    }
  });
});