define('bottomline-execution/models/site', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    appConfiguration: Ember.inject.service(),

    Address: attr('string'),
    Address2: attr('string'),
    Address3: attr('string'),
    AutomaticYN: attr('boolean'),
    LoadingID: attr('string'),
    Brand: attr('string'),
    City: attr('string'),
    Contact: attr('string'),
    County: attr('string'),
    CountryCode: attr('string'),
    Email: attr('string'),
    ExtInvoiceDebtorID: attr('string'),
    ExtSiteID: attr('string'),
    Fax: attr('string'),
    latitude: attr('string'),
    longitude: attr('string'),
    Name: attr('string'),
    Name2: attr('string'),
    Name3: attr('string'),
    Owner: attr('string'),
    SiteType: attr('string'),
    SmsTelephone: attr('string'),
    Telephone: attr('string'),
    ZipCode: attr('string'),
    customer: attr({
      defaultValue: function defaultValue(site) {
        var customerId = 'site-' + site.id + '-customer';
        return site.get('store').createRecord('customer', { id: customerId, siteId: site.id });
      }
    }),

    trip_stops: hasMany('trip-stop'),
    storages: hasMany('storage', { async: true }),
    site_remarks: hasMany('site-remark'),

    isATGStop: Ember.computed('AutomaticYN', 'SiteType', function () {
      return this.get('AutomaticYN') && this.get('SiteType') === 'S';
    }),
    fullAddress: Ember.computed('Address', 'ZipCode', 'City', 'County', 'CountryCode', function () {
      return [this.get('Address'), [this.get('ZipCode'), this.get('City')].filter(function (stringPart) {
        return stringPart;
      }).join(' '), [this.get('County'), this.get('CountryCode')].filter(function (stringPart) {
        return stringPart;
      }).join(' ')].filter(function (stringPart) {
        return stringPart;
      }).join(',\n');
    }),
    telephoneNumbered: Ember.computed('Telephone', function () {
      if (this.get('Telephone')) {
        return this.get('Telephone').replace(/[^\d]/g, '');
      }
    }),

    generateDeliveryTicket: Ember.computed.alias('customer.generateDeliveryTicket'),

    useFlowMeter: Ember.computed.alias('customer.useFlowMeter')
  });
});