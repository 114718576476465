define('bottomline-execution/serializers/trip-stop-storage', ['exports', 'ember-data', 'active-model-adapter'], function (exports, _emberData, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      storage: { embedded: 'always' },
      confirmed15Qty: { key: 'Confirmed15Qty' }
    }
  });
});