define('bottomline-execution/mixins/picture-browser', ['exports', 'bottomline-execution/config/environment', 'bottomline-execution/utils/test-image-util'], function (exports, _environment, _testImageUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    pictureSaver: Ember.inject.service(),
    store: Ember.inject.service(),
    internetConnection: Ember.inject.service(),
    i18n: Ember.inject.service(),

    retry: 0,
    photosStorageID: null,
    onPictureSuccess: function onPictureSuccess(imageData) {
      var _this = this;

      var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'png';

      var tripStop = this.get('model');

      if (Ember.isEmpty(imageData)) {
        this.get('toast').error(this.get('i18n').t('no_image_data.camera'));
        return;
      }

      tripStop.set('picture_count', (tripStop.get('picture_count') || 0) + 1);

      setTimeout(function () {
        Ember.$('.navbar').show();

        var StorageID = _this.get('photosStorageID');
        var tripStopStorageID = tripStop.get('hasSeningMeter') && StorageID ? StorageID : -1;

        console.info('StorageID is', tripStopStorageID);

        var pictureData = {
          imageData: imageData,
          TableKey: tripStop.id,
          TableName: 'TripStop',
          StorageID: tripStopStorageID,
          Description: format,
          BE_PictureGUID: tripStop.createGuid()
        };

        var picture = _this.get('store').createRecord('picture', pictureData);

        _this.get('pictureSaver').save(picture).finally(function () {
          tripStop.set('hasUnloadedPictures', false);
        });

        _this.set('photosStorageID', null);
      }, 500);
    },


    isAtx: Ember.computed(function () {
      return _environment.default.APP.target === 'atx' && typeof plugins !== 'undefined';
    }),

    onPictureFail: function onPictureFail(message) {
      var _this2 = this;

      setTimeout(function () {
        Ember.$(".navbar").show();
        _this2.set('photosStorageID', null);
        _this2.get('model').set('hasUnloadedPictures', false);
      }, 500);
      alert(message);
    },
    showBrowser: function showBrowser(pictures) {
      this.get('f7').hideIndicator();

      if (pictures) {
        var parameters = {
          swipeToClose: false,
          minZoom: 1,
          exposition: false,
          maxZoom: 1,
          onClose: function onClose() {
            Ember.$(".navbar").show();
          },

          photos: pictures,
          type: 'standalone',
          navbarTemplate: '<div class="navbar"> <div class="navbar-inner"> <div class="left sliding"> <a href="javascript:void(0);" class="link close-popup photo-browser-close-link"> <i class="icon icon-back"></i> <span>Close</span> </a> </div> <div class="center sliding"> <span class="photo-browser-current"></span> <span class="photo-browser-of">of</span> <span class="photo-browser-total"></span> </div> <div class="right"></div> </div> </div>',
          swiper: {
            lazy: { enabled: true }
          }
        };

        this.get('f7').photoBrowser(parameters).open();
      }
    },


    actions: {
      capture: function capture(tripStop, StorageID) {
        if (StorageID) {
          this.set('photosStorageID', Number(StorageID));
        }

        tripStop.set('hasUnloadedPictures', true);

        if (!navigator.camera) {
          if (_environment.default.environment !== 'test') {
            alert('No camera found, so testing');
          }

          var picture = tripStop.get('pictures').createRecord({
            imageData: (0, _testImageUtil.testImage)(),
            TableKey: tripStop.id,
            TableName: 'TripStop',
            Description: 'png',
            PictureType: 'picture',
            BE_PictureGUID: tripStop.createGuid()
          });

          this.get('pictureSaver').save(picture).finally(function () {
            tripStop.set('hasUnloadedPictures', false);
          });

          tripStop.set('picture_count', (tripStop.get('picture_count') || 0) + 1);
        } else {
          console.log('photosStorageID is', this.get('photosStorageID'));

          navigator.camera.getPicture(Ember.run.bind(this, this.onPictureSuccess), Ember.run.bind(this, this.onPictureFail), {
            quality: 99,
            destinationType: Camera.DestinationType.DATA_URL,
            sourceType: Camera.PictureSourceType.CAMERA,
            targetWidth: 1024,
            targetHeight: 800,
            saveToPhotoAlbum: false,
            encodingType: Camera.EncodingType.PNG
          });
        }
      },
      viewGallery: function viewGallery(item) {
        var _this3 = this;

        if (this.get('internetConnection.online')) {
          this.get('f7').showIndicator();
          item.store.query('picture', { TableKey: item.id, TableName: 'TripStop' }, { reload: true }).then(function () {
            var remoteItems = item.store.peekAll('picture').filter(function (pic) {
              return Number(pic.get('TableKey')) === Number(item.id);
            }).filter(function (pic) {
              return Number(pic.get('url.length') > 1);
            });

            _this3.get('f7').hideIndicator();
            _this3.showBrowser(remoteItems.map(function (picture) {
              return {
                caption: _this3.get('isAtx') ? _this3.get('i18n').t('preview_not_available_on_atx') : '',
                url: _this3.get('isAtx') ? 'assets/no_picture.png' : picture.get('fullUrl') };
            }));
          });
        } else {
          var localEmberDataRecords = item.store.peekAll('picture').filter(function (picture) {
            return picture.get('url.length') > 0 && Number(picture.get('TableKey')) === Number(item.id);
          }).map(this._showNoInternet.bind(this));

          this.showBrowser(localEmberDataRecords);
        }
      }
    },
    _showLoader: function _showLoader() {
      return {
        caption: this.get('i18n').t("wait_for_upload"),
        url: 'assets/loader.gif'
      };
    },
    _showNoInternet: function _showNoInternet() {
      return {
        caption: this.get('i18n').t("cannot_load_connection_down"),
        url: 'assets/no_internet.png'
      };
    }
  });
});