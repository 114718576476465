define('bottomline-execution/controllers/application', ['exports', 'bottomline-execution/utils/url-utils'], function (exports, _urlUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    adrReportController: Ember.inject.controller('shifts.adr-report'),

    queryParams: ['editTruck'],

    session: Ember.inject.service(),
    locationService: Ember.inject.service('location'),
    loggingService: Ember.inject.service('logging'),
    offlineSyncerService: Ember.inject.service('offline-syncer'),
    i18n: Ember.inject.service(),
    scopedData: Ember.inject.service(),
    androidPermissions: Ember.inject.service(),
    internetConnection: Ember.inject.service(),
    applicationReload: Ember.inject.service(),

    isGeneratingAdrReport: false,

    _setup: Ember.on('init', function () {
      this.get('applicationReload');
      this.get('locationService').set('store', this.get('store'));
      this.get('scopedData').set('store', this.get('store'));
      this.get('offlineSyncerService').set('store', this.get('store'));
      this.get('loggingService'); // Initialize service
      this.get('internetConnection').set('store', this.get('store'));
    }),

    currentTruckChanged: Ember.observer('model.currentTruck', function () {
      this.get('locationService').set('truckId', this.get('model').get('currentTruck'));
    }),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('model'),
    truckSettings: Ember.computed.alias('appConfiguration.truckSettings'),
    isShiftIndex: Ember.computed.equal('currentPath', 'shifts.index'),
    withinShift: Ember.computed('currentPath', function () {
      return this.get('currentPath').indexOf('trips') > -1;
    }),
    blockExit: Ember.computed('currentPath', function () {
      return this.get('currentPath').indexOf('trips') > -1 || this.get('currentPath').indexOf('adr') > -1;
    }),
    canGenerateADRReport: Ember.computed('internetConnection.isBadQuality', 'appState.anyShiftStarted', 'isGeneratingAdrReport', function () {
      return !this.get('internetConnection.isBadQuality') && this.get('appState.anyShiftStarted') && !this.get('isGeneratingAdrReport');
    }),
    adrReportTemplateName: Ember.computed(function () {
      return this.get('appState.shift.adr_report_template_name');
    }),
    doesTrailerExist: Ember.computed('appState.currentTrailer', function () {
      return this.get('appState.currentTrailer');
    }),
    backendEnvironment: Ember.computed('appState.alias', function () {
      var url = this.get('appState.domain');
      var alias = this.get('appState.alias');
      var domain = _urlUtils.default.extractDomainName(url);

      if (_urlUtils.default.isIpAddress(domain)) {
        // If the domain is an IP address, show it as-is
        return domain;
      } else {
        if (Ember.isPresent(alias)) {
          return alias;
        } else {
          return '';
        }
      }
    }),
    canOpenShifts: Ember.computed('appState.currentTruck', function () {
      return Ember.isPresent(this.get('appState.currentTruck'));
    }),
    actions: {
      back: function back() {
        window.history.back();
      },
      navigate: function navigate(path) {
        switch (path) {
          case 'shifts.index':
            this.transitionToRoute('edit-truck');
            break;
          case 'shifts.show.trips.index':
            alert(this.get('i18n').t('back_button_not_supported'));
            break;
          case 'shifts.show.trips.show.trip-stops.show.index':
            alert(this.get('i18n').t('back_button_not_supported'));
            break;
          default:
        }
      },
      adrReport: function adrReport() {
        var _this = this;

        var adrReportTemplateName = this.get('adrReportTemplateName');
        var recentLoadStopId = -1;
        var store = this.get('store');
        // template should be set in the database, uncomment this line for testing purposes
        // adrReportTemplateName = 'adr-report-neot';

        if (adrReportTemplateName) {
          // generate ADR with jsreports
          this.set('isGeneratingAdrReport', true);

          var recentLoadTripStopPayload = store.get('shiftMeta').recentLoadTripStop;
          if (Ember.isPresent(recentLoadTripStopPayload)) {
            recentLoadStopId = recentLoadTripStopPayload.trip_stop.id;
          }

          var adrReportController = this.get('adrReportController');
          adrReportController.get('jsreportsAdr').call(adrReportController, adrReportTemplateName, recentLoadStopId).then(function () {
            return _this.set('isGeneratingAdrReport', false);
          });
        } else {
          // open the page with information which will be in the ADR with an option generate it locally
          this.transitionToRoute('shifts.adr-report', this.get('appState.selectedShift'));
        }
      },
      sync: function sync() {
        this.get('f7').confirm(this.get('i18n').t('sync.warning'), this.get('i18n').t('sync.title'), Ember.run.bind(this, function () {
          window.location.reload();
        }));
      }
    }
  });
});