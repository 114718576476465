define('bottomline-execution/models/state', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;


  var defaultScanner = 'hp';

  exports.default = Model.extend({
    customerConfiguration: Ember.inject.service(),
    onReady: Ember.on('ready', function () {
      var state = this.store.peekRecord('state', 1);
      if (Ember.isEmpty(state.get('scanner_brand'))) {
        // Set default value in this hook to prevent application store cache data to hold scanner_brand set to null
        // Using `scanner_brand: attr('string', { defaultValue: ... })` will not update value in application store cache`
        state.set('scanner_brand', defaultScanner);
      }
    }),

    selectedTrip: attr('string'),
    selectedShift: attr('string'),
    startedShift: attr('string'),
    selectedTripStop: attr('string'),
    scanner_ssid: attr('string'),
    scanner_password: attr('string'),
    scanner_brand: attr('string'),
    currentTruck: attr('string'),
    currentTrailer: attr('string'),
    language: attr('string'),
    domain: attr('string'),
    alias: attr('string'),
    android_logging: attr('boolean', { defaultValue: false }),
    toggleShowMaterialCalculation: attr('boolean'),
    toggleShowProblemAtgSites: attr('boolean'),
    toggleShowMap: attr('boolean'),
    toggleShowDetails: attr('boolean'),
    toggleShowRemarks: attr('boolean'),
    toggleShowMaterialOnTruck: attr('boolean'),
    toggleShowSiteLoadData: attr('boolean'),
    toggleShowActions: attr('boolean', { defaultValue: true }),
    anyShiftStarted: attr('number', { defaultValue: false }),

    getTruck: function getTruck() {
      var truckId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('currentTruck');

      return this.store.peekRecord('truck', truckId);
    },
    getTrailer: function getTrailer() {
      var trailerId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('currentTrailer');

      return this.store.peekRecord('trailer', trailerId);
    },
    setSelectedShift: function setSelectedShift(shiftId) {
      this.set('selectedShift', shiftId);
      this._updateSelectedTrip({ change: 'shiftId' });
    },
    setSelectedTrip: function setSelectedTrip(tripId) {
      this.set('selectedTrip', tripId);
      this._updateSelectedShift();
      this._updateSelectedTripStop();
    },
    setSelectedTripStop: function setSelectedTripStop(tripStopId) {
      this.set('selectedTripStop', tripStopId);
      this._updateSelectedTrip({ change: 'tripStopId' });
    },
    _updateSelectedShift: function _updateSelectedShift() {
      var shiftId = this.get('trip.shift.id');
      if (shiftId) {
        this.set('selectedShift', shiftId);
      }
    },
    _updateSelectedTrip: function _updateSelectedTrip(_ref) {
      var _ref$change = _ref.change,
          change = _ref$change === undefined ? undefined : _ref$change;

      if (change === 'shiftId') {
        var currentSelectedTripId = this.get('selectedTrip');
        var tripIdsInSelectedShift = this.get('shift.sortedTrips').map(function (t) {
          return t.get('id');
        });

        if (!tripIdsInSelectedShift.includes(currentSelectedTripId)) {
          var tripId = tripIdsInSelectedShift.get('firstObject');
          this.set('selectedTrip', tripId);
          this._updateSelectedTripStop();
        }
      }
      if (change === 'tripStopId') {
        var _tripId = this.get('tripStop.trip.id');
        this.set('selectedTrip', _tripId);
        this._updateSelectedShift();
      }
    },
    _updateSelectedTripStop: function _updateSelectedTripStop() {
      var currentSelectedTripStopId = this.get('selectedTripStop');
      var tripStopIdsInSelectedTrip = this.get('trip.sortedDisplayableTripStops').map(function (t) {
        return t.get('id');
      });

      if (!tripStopIdsInSelectedTrip.includes(currentSelectedTripStopId)) {
        var tripStopId = tripStopIdsInSelectedTrip.get('firstObject');
        this.set('selectedTripStop', tripStopId);
      }
    },


    shift: Ember.computed('selectedShift', function () {
      return this.store.peekRecord('shift', this.get('selectedShift'));
    }).volatile(),

    trip: Ember.computed('selectedTrip', function () {
      return this.store.peekRecord('trip', this.get('selectedTrip'));
    }).volatile(),

    tripStop: Ember.computed('selectedTripStop', function () {
      return this.store.peekRecord('trip-stop', this.get('selectedTripStop'));
    }),

    truckSettings: Ember.computed('currentTruck', function () {
      var settingsId = this.get('currentTruck') + 'settings';
      var settings = void 0;
      settings = this.get('store').peekRecord('truck-setting', settingsId);
      if (settings) {
        return settings;
      }

      settings = this.get('store').createRecord('truck-setting', { id: settingsId });
      return settings;
    })
  });
});