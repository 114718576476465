define('bottomline-execution/services/customer-configuration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),

    loadSettings: function loadSettings(truckId) {
      var _this = this;

      if (Ember.isEmpty(truckId)) {
        truckId = 'default';
      }

      return this.get('ajax').request(['/bottomline_execution_configuration', truckId].join('?truck_id=')).then(function (data) {
        var settingsId = [truckId, 'settings'].join('');
        var currentSettings = _this.get('store').peekRecord('truck-setting', settingsId) || _this.get('store').createRecord('truck-setting', { id: settingsId });

        currentSettings.setProperties(data);
        currentSettings.save();

        return currentSettings;
      }).catch(function (e) {
        console.error('Error on endpoint, configuration not loaded', e);
      });
    }
  });
});