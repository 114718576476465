define('bottomline-execution/components/retail-site-form/authorize-row-preview', ['exports', 'bottomline-execution/utils/initials'], function (exports, _initials) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',

    relatedTripStopTruckStorage: Ember.computed('tstsCompartments', {
      get: function get() {
        return this.get('tstsCompartments').filterBy('TruckStorageID', this.get('compartment.truck_storage_id'))[0];
      }
    }),

    compartmentIsChanged: Ember.observer('compartment.isSigned', function () {
      if (this.get('compartment.isSigned')) {
        this.get('compartment').setProperties({
          authorizerInitials: (0, _initials.initials)(this.get('authorizerName'), this.get('authorizerSurName'))
        });
      } else {
        this.get('compartment').setProperties({
          authorizerInitials: ''
        });
      }
    })
  });
});