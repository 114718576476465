define('bottomline-execution/adapters/picture', ['exports', 'bottomline-execution/adapters/application', 'bottomline-execution/utils/data-uri-to-blob-util', 'bottomline-execution/utils/form-data-util', 'bottomline-execution/utils/file-to-b64-util'], function (exports, _application, _dataUriToBlobUtil, _formDataUtil, _fileToB64Util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    formMultipart: Ember.inject.service(),
    pictureSaver: Ember.inject.service(),

    createRecord: function createRecord(store, type, snapshot) {
      var _this = this;

      var pictureData = {};
      var serializer = store.serializerFor('pictures');

      serializer.serializeIntoHash(pictureData, type, snapshot);

      var pictureDataForm = function pictureDataForm(pictureData, pictureImageData) {
        return (0, _formDataUtil.formDataBuilder)({
          picture: Object.assign({}, pictureData, {
            image_data: (0, _dataUriToBlobUtil.dataURItoBlobUtil)('data:image/jpeg;base64,' + pictureImageData)
          })
        });
      };

      var request = function request(pictureDataForm) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          var imageSize = pictureDataForm.get('picture[image_data]').size;
          if (imageSize <= 0) {
            return reject('Picture file missing data');
          }

          return _this.get('formMultipart').request('/pictures/', {
            processData: false,
            contentType: false,
            method: 'POST',
            data: pictureDataForm
          }).then(function (data) {
            var picture = snapshot.record;
            var tripStop = store.peekRecord(picture.get('TableName'), picture.get('TableKey'));
            tripStop.set('hasUnloadedPictures', false);

            Ember.run(null, resolve, data);
          }).catch(function (data) {
            Ember.run(null, reject, data);
          });
        });
      };

      if (window.cordova) {
        return this.get('pictureSaver').findPictureFileByGuid(snapshot.record.get('BE_PictureGUID')).then(function (entry) {
          return (0, _fileToB64Util.fileToB64Util)(entry.nativeURL).then(function (base64String) {
            return request(pictureDataForm(pictureData.picture, base64String.split('base64,')[1]));
          });
        });
      } else {
        return request(pictureDataForm(pictureData.picture, pictureData.picture.image_data));
      }
    }
  });
});