define('bottomline-execution/components/shift-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    askForOdometer: Ember.computed.alias('appConfiguration.truckSettings.odometer_on_shift_level'),

    didRender: function didRender() {
      var _this = this;

      Ember.run.later(function () {
        if (_this.get('appState.selectedShift')) {
          _this.get('set-active-shift')(_this.get('appState.selectedShift'));
        }

        if (_this.get('appState.selectedTrip')) {
          _this.get('set-active-trip')(_this.get('appState.selectedTrip'));
        }
      }, 1000);
    },


    orderedShifts: Ember.computed.alias('shifts'),
    sortedShifts: Ember.computed('sortedFinishedShifts', 'sortedUnfinishedShifts', function () {
      return this.get('sortedFinishedShifts').concat(this.get('sortedUnfinishedShifts'));
    }),
    sortedFinishedShifts: Ember.computed.sort('finishedShifts', 'finishedShiftsSortOrder'),
    sortedUnfinishedShifts: Ember.computed.sort('unfinishedShifts', 'unfinishedShiftsSortOrder'),
    finishedShifts: Ember.computed.filterBy('shifts', 'ConfirmedStartDT'),
    unfinishedShifts: Ember.computed.filterBy('shifts', 'ConfirmedStartDT', null),
    finishedShiftsSortOrder: ['ConfirmedStartDT'],
    unfinishedShiftsSortOrder: ['PlannedStartDT'],

    actions: {
      updateState: function updateState(shift, state) {
        var _this2 = this;

        if (state.disabled) {
          return;
        }

        if (state.state === 'started') {
          // Start and Restart
          this._askForOdometer(shift, this.get('i18n').t('start_of_shift'), 'OdometerStart').then(function () {
            shift.start().then(function () {
              _this2._updateAppState(shift);
              _this2.attrs['navigate-to-trip-page'](shift);
            });
          });
        } else if (state.state === 'finished') {
          this._askForOdometer(shift, this.get('i18n').t('end_of_shift'), 'OdometerEnd').then(function () {
            shift.finish().then(function () {
              return _this2._updateAppState(shift);
            });
          });
        } else {
          shift.cancel().then(function () {
            return _this2._updateAppState(shift);
          });
        }
      },
      selectShift: function selectShift(itemId) {
        var appState = this.get('appState');
        var shift = this.get('store').peekRecord('shift', itemId);
        appState.setProperties({
          selectedShift: itemId,
          currentTruck: shift.get('TruckID'),
          currentTrailer: shift.get('TrailerID'),
          selectedTrip: null,
          selectedTripStop: null
        });

        appState.save();
        this.attrs['set-active-shift'](itemId);
        this.attrs['unset-active-trip']();
      },
      onShiftTripNavigateClick: function onShiftTripNavigateClick(trip) {
        var _this3 = this;

        this._selectTrip(trip.get('id')).then(function () {
          _this3.attrs['on-shift-trip-navigate-click'](trip);
        });
      },
      selectTrip: function selectTrip(itemId) {
        this._selectTrip(itemId);
      }
    },

    _selectTrip: function _selectTrip(itemId) {
      var appState = this.get('appState');
      appState.set('selectedTrip', itemId);
      return appState.save();
    },
    _askForOdometer: function _askForOdometer(shift, prompt, fieldName) {
      var askForOdometer = this.get('askForOdometer');
      var deferred = Ember.RSVP.defer();

      if (!askForOdometer) {
        deferred.resolve();

        return deferred.promise;
      }

      var odometer = shift.get(fieldName);
      this.get('f7').extdPrompt(prompt, this.get('i18n').t('form_odometer'), 'number', odometer, function (e) {
        if (e !== '') {
          if (e !== odometer) {
            shift.set(fieldName, e);
            shift.save();
          }

          deferred.resolve();
        }
      }, function () {
        deferred.reject();
      });
      return deferred.promise;
    },
    _updateAppState: function _updateAppState(shift) {
      var appState = this.get('appState');

      appState.set('startedShift', null);
      appState.set('anyShiftStarted', shift.get('started'));
      if (shift.get('started')) {
        appState.set('startedShift', shift);
      }

      return appState.save();
    }
  });
});