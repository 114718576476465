define('bottomline-execution/components/authorize-form', ['exports', 'moment', 'bottomline-execution/utils/initials'], function (exports, _moment, _initials) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    pictureSaver: Ember.inject.service(),

    resultPDF: null,
    doc: null,
    picture: null,
    toTripStop: '',
    toTrip: '',
    toNextState: '',
    cancel: '',
    postProcess: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var callback = function callback() {
        var activeElement = document.activeElement;
        if (!activeElement.matches('input')) return;
        activeElement.blur();
      };

      this._getSignatureCanvas().addEventListener('touchstart', callback, false);
      this.element.addEventListener('touchstart', callback, false);
    },
    willDestroyElement: function willDestroyElement() {
      this.element.removeEventListener('touchstart');
      this._getSignatureCanvas().removeEventListener('touchstart');

      this._super.apply(this, arguments);
    },
    _getSignatureCanvas: function _getSignatureCanvas() {
      return this.element.querySelector('div#authorizer_signature > canvas');
    },
    addPictureToTripStop: function addPictureToTripStop(tripStop, properties) {
      var picture = this.get('store').createRecord('picture', properties);

      this.get('pictureSaver').save(picture);

      tripStop.get('pictures').pushObject(picture);
      tripStop.set('picture_count', (tripStop.get('picture_count') || 0) + 1);

      return picture;
    },


    actions: {
      authorize: function authorize() {
        var _this = this;

        var svg = document.getElementsByTagName('canvas')[0];
        var doc = new jsPDF('p', 'pt', 'A4');
        var fileReader = new FileReader();

        fileReader.onload = function (e) {
          var tripStop = _this.get('trip-stop');

          _this.set('resultPDF', e.currentTarget.result);

          var picture = _this.addPictureToTripStop(tripStop, {
            imageData: e.currentTarget.result.split('base64,')[1],
            TableName: 'TripStop',
            TableKey: tripStop.get('id'),
            Description: 'pdf',
            PictureType: 'picture',
            BE_PictureGUID: tripStop.createGuid()
          });

          _this.set('picture', picture);

          _this.addPictureToTripStop(tripStop, {
            imageData: _this.get('signatureImage').split('base64,')[1],
            TableName: 'TripStop',
            TableKey: tripStop.get('id'),
            Description: _this.get('signatureDescription'),
            PictureType: 'signature',
            BE_PictureGUID: tripStop.createGuid()
          });

          _this.get('f7').hideIndicator();
          _this.send('toNextState');
        };

        this.get('trip-stop.tstsCompartments').forEach(function (compartment) {
          compartment.set('startQty', compartment.get('truckInventory.currentQty'));
        });

        var signaturePosition = this.get('trip-stop.tstsCompartments').filterBy('isSigned', true).length * 14 + 585;
        var documentWidth = 600;

        doc.addImage(svg.toDataURL("image/pdf"), 'PDF', 20, signaturePosition, 120, 60);

        if (this.get('postProcess')) {
          Ember.$('#postpreview').removeClass('is-hidden');
          doc.fromHTML(Ember.$('#postpreview').get(0), 20, 5, { width: documentWidth });
        } else {
          Ember.$('#preview').removeClass('is-hidden');
          doc.fromHTML(Ember.$('#preview').get(0), 20, 5, { width: documentWidth });
        }

        fileReader.readAsDataURL(doc.output('blob'));
        this.get('f7').showIndicator();

        return false;
      },
      reset: function reset() {
        this.set('signature', Ember.A());
      },
      toNextState: function toNextState() {
        this.get('toNextState')();
      },
      cancel: function cancel() {
        this.get('cancel')();
      }
    },

    signatureImage: Ember.computed('signature.[]', {
      get: function get() {
        var svg = document.getElementsByTagName('canvas')[0];

        if (svg) {
          return svg.toDataURL("image/png");
        }
      }
    }),

    signatureDescription: Ember.computed(function () {
      if (this.get('postProcess')) {
        return this.get("signaturePostDescription");
      } else {
        return this.get("signaturePreDescription");
      }
    }),

    signaturePreDescription: Ember.computed(function () {
      var _this2 = this;

      var desc = "{'type': 'pre'," + "'firstname': '" + this.get('trip-stop.authorizerName') + "'," + "'lastname': '" + this.get('trip-stop.authorizerSurName') + "'," + "'signdate': '" + (0, _moment.default)().format('YYYY-MM-DD HH:mm') + "'," + "'signdisclaimer': '" + document.getElementById(this.get('disclaimerTag')).textContent + "'," + "'compartment': [";
      var compartmentCount = 0;
      this.get('trip-stop.tstsCompartments').forEach(function (compartment) {
        if (compartment.get("authorizerInitials")) {
          var tstsStorage = _this2.get('trip-stop.tstsStorages').filterBy('TruckStorageID', compartment.get('truck_storage_id'))[0] || Ember.Object.create({});
          var materialId = compartment.get('material_id') || '';
          var material = _this2.get('store').peekRecord('material', materialId);
          var materialAdr = _this2.get('store').peekRecord('adr-material', materialId);
          var materialName = '';
          var materialAdrCode = '';
          if (!(material === null || material === undefined)) {
            materialName = material.get("MaterialNameLong");
          }
          if (!(materialAdr === null || materialAdr === undefined)) {
            materialAdrCode = materialAdr.get("materialAdrCode");
          }
          desc = desc + "{" + "'Nb': '" + compartment.get("TruckStorageID") + "'," + "'Product': '" + materialName + "'," + "'AdrCode': '" + materialAdrCode + "'," + "'StorageId': '" + tstsStorage.get("StorageID") + "'," + "'TankNb': '" + compartment.get("tankReference") + "'," + "'Current': '" + compartment.get("currentQty") + "'," + "'Planned': '" + tstsStorage.get("plannedQty") + "'," + "'Initials': '" + compartment.get("authorizerInitials") + "'" + "},";
          compartmentCount += 1;
        }
      });
      if (compartmentCount > 0) {
        desc = desc.substr(0, desc.length - 1);
      }
      desc = desc + "]}";
      return desc;
    }),

    signaturePostDescription: Ember.computed(function () {
      var _this3 = this;

      var descriptionContent = {
        type: 'post',
        firstname: this.get('trip-stop.authorizerName'),
        lastname: this.get('trip-stop.authorizerSurName'),
        signdate: (0, _moment.default)().format('YYYY-MM-DD HH:mm'),
        signdisclaimer: document.getElementById(this.get('disclaimerTag')).textContent,
        compartment: []
      };

      descriptionContent.compartment = this.get('trip-stop.compartments').reject(function (compartment) {
        return compartment.get('confirmedQty') === null || compartment.get('confirmedQty') === undefined || compartment.get('confirmedQty') === 0;
      }).map(function (compartment) {
        var material = compartment.get('material');
        var materialName = '';
        var materialId = '';
        var materialAdrCode = '';
        var loadData = _this3.loadData(compartment.get('TruckStorageID'));
        var loadedQty = loadData['confirmedQty'];
        var loaded15Qty = loadData['confirmed15Qty'];
        var loadTemperature = loadData['Temperature'];
        var deliveredQty = compartment.get('confirmedQty');
        var delivered15Qty = _this3.deriveConfirmed15Qty(compartment);

        if (!(material === null || material === undefined)) {
          materialName = material.get('MaterialNameLong');
          materialId = material.get('id');
        } else {
          materialName = loadData.materialNameLong;
          materialId = loadData.materialId;
        }
        console.log(loadData);
        console.log(materialName);
        console.log(materialId);
        var materialAdr = _this3.get('store').peekRecord('adr-material', materialId);
        if (!(materialAdr === null || materialAdr === undefined)) {
          materialAdrCode = materialAdr.get('materialAdrCode');
        }
        if (deliveredQty === '' || deliveredQty === null || deliveredQty === undefined) {
          deliveredQty = 0;
        }
        if (delivered15Qty === '' || delivered15Qty === null || delivered15Qty === undefined) {
          delivered15Qty = 0;
        }
        if (loadTemperature === null || loadTemperature === undefined) {
          loadTemperature = '';
        }

        var row = {
          Nb: compartment.get('TruckStorageID'),
          Product: materialName,
          AdrCode: materialAdrCode,
          StorageId: compartment.get('StorageID'),
          TruckStorageId: compartment.get('TruckStorageID'),
          Planned: compartment.get('plannedQty'),
          Loaded: loadedQty,
          Loaded15: loaded15Qty,
          Delivered: deliveredQty,
          Delivered15: delivered15Qty,
          LoadTemperature: loadTemperature,
          DeliveryTemperature: compartment.get('Temperature'),
          Initials: (0, _initials.initials)(_this3.get('trip-stop.authorizerName'), _this3.get('trip-stop.authorizerSurName'))
        };

        return row;
      }).sort(function (a, b) {
        return Number(a.TruckStorageId) - Number(b.TruckStorageId);
      });

      return JSON.stringify(descriptionContent);
    }),

    loadData: function loadData(truckStorageId) {
      var result = {};
      result['confirmedQty'] = 0;
      result['confirmed15Qty'] = 0;
      result['Temperature'] = 0;
      result['count'] = 0;

      var tsts = this.loadStopCompartment(truckStorageId);

      if (Ember.isPresent(tsts)) {
        result['materialId'] = tsts.get('material.id');
        result['materialNameLong'] = tsts.get('material.MaterialNameLong');
        result['confirmedQty'] = tsts.get('confirmedQty');
        result['confirmed15Qty'] = tsts.get('confirmed15Qty');
        result['Temperature'] = tsts.get('Temperature');
      }

      return result;
    },
    deriveConfirmed15Qty: function deriveConfirmed15Qty(compartment) {
      var truckStorageId = compartment.get('TruckStorageID');
      var loadStopCompartment = this.loadStopCompartment(truckStorageId);
      var derivedConfirmed15Qty = compartment.deriveConfirmed15Qty(loadStopCompartment);
      return Math.round(derivedConfirmed15Qty);
    },
    loadStopCompartment: function loadStopCompartment(truckStorageID) {
      var loadStopCompartments = this.get('trip-stop.trip.loadStop.trip_stop_truck_storages');
      return loadStopCompartments.find(function (loadStopCompartment) {
        return loadStopCompartment.get('TruckStorageID') == truckStorageID;
      });
    },
    deliveryTemperature: function deliveryTemperature(materialId) {
      var temperature = null;
      this.get('trip-stop.trip_stop_storages').filterBy('MaterialID', materialId).forEach(function (tss) {
        temperature = tss.get("Temperature");
      });
      return temperature;
    },


    canPreAuthorize: Ember.computed('trip-stop.canPreAuthorize', 'signature.[]', function () {
      return this.get('trip-stop.canPreAuthorize') && Ember.isPresent(this.get('signature'));
    }),

    canPostAuthorize: Ember.computed('trip-stop.canPostAuthorize', 'signature.[]', function () {
      return this.get('trip-stop.canPostAuthorize') && Ember.isPresent(this.get('signature'));
    }),

    disclaimerTag: Ember.computed(function () {
      return (this.get('postProcess') ? 'post' : 'pre') + '-disclaimer';
    }),

    surAndLastName: Ember.computed('trip-stop.{authorizerName,authorizerSurName}', function () {
      return [this.get('trip-stop.authorizerName'), this.get('trip-stop.authorizerSurName')].join(' ');
    }),

    signature: Ember.computed(function () {
      return Ember.A();
    }),

    stringifiedSignature: Ember.computed('signature.[]', function () {
      return JSON.stringify(this.get('signature'));
    })
  });
});