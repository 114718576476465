define('bottomline-execution/utils/trip-stop-truck-storage-util', ['exports', 'bottomline-execution/utils/storage-id-for-material-id-util'], function (exports, _storageIdForMaterialIdUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.tripStopTruckStorageUtil = undefined;


  function tripStopTruckStorageUtil(tripStop, storages, truckStorageID, truckInventory) {
    var tsts = tripStop.get('trip_stop_truck_storages').findBy('TruckStorageID', truckStorageID);

    if (Ember.isEmpty(tsts)) {
      tsts = tripStop.get('trip_stop_truck_storages').createRecord({
        id: tripStop.createGuid(),
        TripStopID: tripStop.id,
        ActivityCode: tripStop.get('ActivityCode'),
        StorageID: (0, _storageIdForMaterialIdUtil.storageIdForMaterialIDUtil)(truckInventory.get('material_id'), storages),
        TripID: tripStop.get('TripID'),
        TruckID: truckInventory.get('truckStorage.truck_id'),
        TruckStorageID: truckStorageID,
        plannedQty: 0
      });
    }

    return tsts;
  }

  exports.tripStopTruckStorageUtil = tripStopTruckStorageUtil;
});