define('bottomline-execution/services/request-headers', ['exports', 'bottomline-execution/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    deviceInformation: Ember.inject.service(),
    session: Ember.inject.service(),

    headers: function headers() {
      var _this = this;

      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref$authenticate = _ref.authenticate,
          authenticate = _ref$authenticate === undefined ? false : _ref$authenticate;

      return new Ember.RSVP.Promise(function (resolve) {
        var headers = _this.defaultHeaders({ authenticate: authenticate });

        _this.get('deviceInformation').getMacAddress().then(function (macaddress) {
          headers['device-guid'] = macaddress;
          resolve(headers);
        }, function (_failure) {
          resolve(headers); // Do not set 'device-guid' if there is no MAC address
        });
      });
    },
    defaultHeaders: function defaultHeaders() {
      var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref2$authenticate = _ref2.authenticate,
          authenticate = _ref2$authenticate === undefined ? false : _ref2$authenticate;

      var headers = { 'App-Version': _environment.default.APP.version.split('+')[0],
        'Content-Type': 'application/json' };

      if (authenticate) {
        this.get('session').authorize('authorizer:devise', function (headerName, headerValue) {
          headers[headerName] = headerValue;
        });
      }

      return headers;
    }
  });
});