define("bottomline-execution/utils/math-util", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function round(number) {
    var digitsAfterComma = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

    if (isInt(number)) {
      return number;
    } else {
      var base = Math.pow(10, digitsAfterComma);
      return (Math.round(number * base) / base).toFixed(digitsAfterComma);
    }
  }

  function isInt(number) {
    return parseInt(number) === number;
  }

  exports.round = round;
  exports.isInt = isInt;
});