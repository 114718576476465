define('bottomline-execution/components/shift-item', ['exports', 'bottomline-execution/mixins/trip-sorter'], function (exports, _tripSorter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_tripSorter.default, {
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),

    tagName: '',

    selectedShift: Ember.computed.alias('parentController.selectedShift'),
    truckSettings: Ember.computed.alias('appConfiguration.truckSettings'),
    isSelected: Ember.computed('selectedShift', function () {
      return this.get('selectedShift') === this.get('shift.id');
    }),

    trips: Ember.computed.alias('shift.trips'),

    actions: {
      select: function select() {
        var _this = this;

        this.get('store').findRecord('shift', this.get('shift.id')).then(function (shift) {
          _this.set('parentController.selectedShift', shift.id);

          _this.set('parentController.selectedTrip', null);
        });

        return false;
      }
    }
  });
});