define('bottomline-execution/components/trip-stop-truck-storage-load', ['exports', 'bottomline-execution/utils/trip-stop-truck-storage-util'], function (exports, _tripStopTruckStorageUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),

    classNames: 'storage-compartment',
    classNameBindings: ['isHighlightedCompartment', 'isHiddenCompartment'],
    tagName: 'td',

    tripStopStorageChanged: Ember.observer('tripStopStorage', function () {
      this.set('lock', null);
    }),

    isHighlightedCompartment: Ember.computed.alias('materialMatch'),
    isHiddenCompartment: Ember.computed.not('showCompartment'),
    showCompartment: Ember.computed('showAllCompartments', 'materialMatch', function () {
      if (this.get('showAllCompartments')) {
        return true;
      } else {
        return this.get('materialMatch');
      }
    }),
    showLock: Ember.computed.and('tripStopStorageSelected', 'materialMismatch'),
    isLocked: Ember.computed('lock', function () {
      if (this.get('lock') === null) {
        this.set('lock', this.get('defaultLockSetting'));
      }
      return this.get('lock');
    }),
    lock: null,
    defaultLockSetting: Ember.computed('tripStopStorageSelected', function () {
      if (this.get('tripStopStorageSelected')) {
        return this.get('materialMismatch');
      } else {
        return !this.get('tripStopTruckStorage.hasPlannedMaterial');
      }
    }),
    canChangeMaterial: Ember.computed.and('tripStopStorageSelected', 'materialMismatch'),
    canEnterQuantity: Ember.computed('isLocked', 'tripStopTruckStorage.MaterialID', function () {
      return !this.get('isLocked') && Ember.isPresent(this.get('tripStopTruckStorage.MaterialID'));
    }),
    canCopyPlannedQuantity: Ember.computed('canEnterQuantity', 'tripStopTruckStorage.hasPlannedMaterial', function () {
      return this.get('canEnterQuantity') && this.get('tripStopTruckStorage.plannedQty') > 0;
    }),
    tripStopStorageSelected: Ember.computed('tripStopStorage', function () {
      return Ember.isPresent(this.get('tripStopStorage'));
    }),
    materialMatch: Ember.computed.alias('plannedMaterialSameAsInSelectedTripStopStorage'),
    materialMismatch: Ember.computed.not('plannedMaterialSameAsInSelectedTripStopStorage'),
    plannedMaterialSameAsInSelectedTripStopStorage: Ember.computed('tripStopStorage.MaterialID', 'tripStopTruckStorage.MaterialID', function () {
      return this.get('tripStopTruckStorage.hasPlannedMaterial') && this.get('tripStopStorage.MaterialID') === this.get('tripStopTruckStorage.MaterialID');
    }),
    trip: Ember.computed.alias('trip-stop.trip'),
    truckInventories: Ember.computed.alias('trip.shift.truck_inventories'),
    truckInventory: Ember.computed('truckInventories', function () {
      var truckStorageID = this.get('truckStorage.TruckStorageID');
      return this.get('truckInventories').findBy('truck_storage_id', truckStorageID);
    }),
    confirmedPriorTripStops: Ember.computed(function () {
      var loadStopArrivalDT = moment(this.get('trip').getLoadStop().get('ConfirmedArrivalDT'));
      return this.get('trip.trip_stops').filter(function (t) {
        var tripStopDepartureDT = moment(t.get('ConfirmedDepartureDT'));
        return t.get('isConfirmed') && !t.get('isLoad') && tripStopDepartureDT.isBefore(loadStopArrivalDT);
      });
    }),
    leftOnVehicle: Ember.computed(function () {
      /*
        The load is added on top of the left-on-vehicle:
          'what is on the vehicle at the start of the load'.
         It is calculated as the sum of the confirmed quantities of all trip stops
        that have been confirmed prior to the start of the load stop. This includes
        the start inventory, but also material transfers and delivery stops.
      */
      var confirmedTripStopIds = this.get('confirmedPriorTripStops').map(function (t) {
        return Number(t.get('id'));
      });
      var truckStorageID = Number(this.get('truckStorage.TruckStorageID'));

      var sumOfConfirmedQty = this.get('store').peekAll('trip-stop-truck-storage').filter(function (t) {
        var tripStopId = Number(t.get('TripStopID'));
        return confirmedTripStopIds.includes(tripStopId) && t.get('TruckStorageID') == truckStorageID;
      }).reduce(function (sum, t) {
        return sum + t.get('confirmedQty');
      }, 0);

      return sumOfConfirmedQty;
    }),
    tripStopTruckStorage: Ember.computed('trip-stop', 'truckStorage', function () {
      var truckStorageID = this.get('truckStorage.TruckStorageID');
      var truckInventory = this.get('truckInventory');

      return (0, _tripStopTruckStorageUtil.tripStopTruckStorageUtil)(this.get('trip-stop'), this.get('trip-stop.site.storages'), truckStorageID, truckInventory);
    }),
    totalConfirmedQty: Ember.computed('leftOnVehicle', 'tripStopTruckStorage.confirmedQty', function () {
      return Number(this.get('leftOnVehicle') || 0) + Number(this.get('tripStopTruckStorage.confirmedQty'));
    }),
    isLoad: Ember.computed.alias('trip-stop.isLoad'),
    showTemperatureDensity15Liters: Ember.computed.alias('trip-stop.showTemperatureDensity15Liters'),
    canNotBeLoadedAutomatically: Ember.computed('isLoad', 'leftOnVehicle', function () {
      return this.get('isLoad') && this.get('leftOnVehicle') > 0;
    }),

    initTripStopStorage: function initTripStopStorage() {
      // try to get selected trip-stop-storage
      var tripStopStorage = this.get('tripStopStorage');

      if (Ember.isEmpty(tripStopStorage)) {
        // no any trip-stop-storage is selected, try to get trip-stop-storage associated with the trip-stop-truck-storage
        tripStopStorage = this.get('tripStopTruckStorage.tripStopStorage');
      }

      if (Ember.isEmpty(tripStopStorage)) {
        // trip-stop-truck-storage has no associated trip-stop-storage,
        // it rather means that nothing was planned for this trip-stop-truck-storage
        // and the trip-stop-truck-storage is not in database yet, it is an empty entity created on frontend

        var storageId = Number(this.get('tripStopTruckStorage.StorageID'));
        var tripStopId = Number(this.get('tripStopTruckStorage.TripStopID'));
        // alternative: Number(this.get('trip-stop.id'))

        tripStopStorage = this.get('store').peekAll('trip-stop-storage').filterBy('StorageID', storageId).filterBy('TripStopID', tripStopId)[0];
      }

      return tripStopStorage;
    },
    initAndlinkTripStopStorage: function initAndlinkTripStopStorage() {
      var tripStopStorage = this.initTripStopStorage();
      if (Ember.isEmpty(tripStopStorage)) {
        return;
      }

      this.get('tripStopTruckStorage').linkTripStopStorage(tripStopStorage);

      return tripStopStorage;
    },
    confirmQty: function confirmQty() {
      var qty = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      var tripStopStorage = this.initAndlinkTripStopStorage();
      if (Ember.isEmpty(tripStopStorage)) {
        return;
      }

      if (qty) {
        this.set('tripStopTruckStorage.confirmedQty', qty);
      }

      tripStopStorage.updateConfirmedQty();
    },


    actions: {
      toggleLock: function toggleLock() {
        this.toggleProperty('lock');
      },
      propagetQuantityChange: function propagetQuantityChange() {
        this.confirmQty();
      },
      copyPlannedQty: function copyPlannedQty(qty) {
        this.confirmQty(qty);
      },
      changePlannedMaterial: function changePlannedMaterial() {
        this.get('tripStopTruckStorage').setProperties({
          StorageID: this.get('tripStopStorage.StorageID'),
          MaterialID: this.get('tripStopStorage.MaterialID'),
          TripStopStorageID: this.get('tripStopStorage.id')
        });
      }
    }
  });
});