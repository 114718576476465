define('bottomline-execution/routes/trip-stops/pok-delivery', ['exports', 'bottomline-execution/mixins/trip-stop-material-on-truck'], function (exports, _tripStopMaterialOnTruck) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_tripStopMaterialOnTruck.default, {
    toast: Ember.inject.service(),
    i18n: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),

    model: function model() {
      var trip = this.modelFor('trips.show');
      var tripId = trip.id;
      var materialsOnTruck = this.tripStopMaterialOnTruck({
        trip: trip,
        filterZeroNeededQty: false,
        includeTrailerInventories: true
      });

      return Ember.RSVP.hash({
        orderDetails: this.get('store').query('order-detail', { trip_id: tripId }),
        materialsOnTruck: materialsOnTruck,
        tripId: tripId
      });
    },

    actions: {
      createTripStop: function createTripStop(orderDetail) {
        var _this = this;

        this.controller.set('isCreatingNewTripStop', true);

        var tripId = this.get('controller.model.tripId');
        var trip = this.get('store').peekRecord('trip', tripId);

        var truckInventories = trip.get('shift.truck_inventories').filter(function (truckInventory) {
          return [_this.get('appState.currentTruck'), _this.get('appState.currentTrailer')].includes(truckInventory.get('truck_id'));
        }).sortBy('truck_storage_id');

        var compatibleTruckInventory = truckInventories.filterBy('material_id', orderDetail.get('MaterialID')).get('firstObject');
        if (Ember.isEmpty(compatibleTruckInventory)) {
          compatibleTruckInventory = truckInventories.filterBy('material.DeliveryCompatibility', orderDetail.get('DeliveryCompatibility')).get('firstObject');
        }

        // set plannedQty to 0 instead of OrderQty for trip stops created from orders,
        // that way we can distinguish regular unload stops from added from orders
        // and handle filtering of trip stop storages in a correct way
        var plannedQty = 0;

        var newTripStop = this.get('store').createRecord('trip-stop', {
          ActivityCode: 'U',
          OrderID: orderDetail.get('OrderID'),
          ExtOrderID: orderDetail.get('OrderExtOrderId'),
          StorageGroupID: orderDetail.get('StorageGroupID'),
          plannedQty: plannedQty,
          trip: trip,
          TripID: tripId,
          state: 'planned',
          Status: 1,
          CompletedYN: false,
          SiteID: orderDetail.get('SiteID'),
          TruckID: trip.get('TruckID'),
          ResourceID: trip.get('ResourceID'),
          DriverRemarks: this.get('i18n').t('driver_added_stop.order') + ' ' + moment().format('L LTS')
        });

        newTripStop.save().then(function (tripStop) {
          _this.get('toast').info(_this.get('i18n').t('pok_delivery_success'));

          // const tripStopStorage = tripStop.get('trip_stop_storages').get('lastObject');
          var tripStopTruckStorage = tripStop.get('trip_stop_truck_storages').get('lastObject');

          var tripStopTruckStorageCopyData = Object.assign({}, tripStopTruckStorage.data, {
            id: newTripStop.createGuid('from pok delivery'),
            ActivityCode: null,
            TripStopStorageID: null,
            TruckStorageID: compatibleTruckInventory.get('truck_storage_id'),
            TruckID: compatibleTruckInventory.get('truck_id')
          });

          var newStopTruckStorage = _this.get('store').createRecord('trip-stop-truck-storage', tripStopTruckStorageCopyData);

          tripStop.get('trip_stop_truck_storages').pushObject(newStopTruckStorage);

          return trip.save().then(function () {
            _this.controller.set('isCreatingNewTripStop', false);
            _this.transitionTo('trips');
          });
        }).catch(function () {
          _this.controller.set('isCreatingNewTripStop', false);
          _this.get('toast').error('Unexpected error occured');
        });
      }
    }
  });
});