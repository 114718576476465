define('bottomline-execution/models/trailer', ['exports', 'ember-data/model', 'ember-data'], function (exports, _model, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = _model.default.extend({
    TruckID: attr('string'),
    License: attr('string'),
    MeterTypeID: attr('string'),
    VehicleType: attr('number'),
    truck_inventories: hasMany('truck-inventory')
  });
});