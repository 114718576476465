define('bottomline-execution/models/base', ['exports', 'ember-data', 'bottomline-execution/utils/guid-util'], function (exports, _emberData, _guidUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model;
  exports.default = Model.extend({
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    createGuid: function createGuid() {
      return (0, _guidUtil.guidUtil)();
    },
    groupBy: function groupBy(list, group, totals) {
      var groupedResult = Ember.A([]);
      var groups = Ember.A(Ember.A(list).mapBy(group)).uniq();

      groups.forEach(function (item) {
        var currentGroup = new Ember.Object();

        var itemsForGroup = list.filterBy(group, item);

        currentGroup.set('items', itemsForGroup);
        currentGroup.set('group', item);
        totals.forEach(function (total) {
          var totalVal = itemsForGroup.reduce(function (prev, item) {
            if (isNaN(prev)) {
              prev = 0;
            }

            return parseInt(prev, 10) + parseInt(item.get(total) || 0, 10);
          }, 0);

          currentGroup.set('total' + total, totalVal);
        });

        if (itemsForGroup.length > 0) {
          groupedResult.push(currentGroup);
        }
      });

      return groupedResult;
    }
  });
});