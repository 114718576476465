define('bottomline-execution/routes/trips/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),

    model: function model() {
      return this.modelFor('trips').get('trips');
    },
    setupController: function setupController(controller, model) {
      var tripsToShow = model.filter(function (trip) {
        return trip.get('started');
      });
      tripsToShow.forEach(function (trip) {
        return trip.calculate();
      });

      tripsToShow.forEach(function (trip) {
        return trip.set('isShown', true);
      });

      var selectedTripStop = this.get('appState.selectedTripStop');
      if (Ember.isEmpty(selectedTripStop)) this._setSelectedTripStop(model);

      this._super(controller, model);
    },
    _setSelectedTripStop: function _setSelectedTripStop(trips) {
      var tripToSelectTripStop = void 0;

      var selectedTripId = this.get('appState.selectedTrip');
      if (selectedTripId) {
        tripToSelectTripStop = trips.toArray().find(function (trip) {
          return trip.get('id') == selectedTripId;
        });
      } else {
        tripToSelectTripStop = trips.toArray().find(function (trip) {
          return trip.get('state') === 'started';
        });
      }
      if (Ember.isEmpty(tripToSelectTripStop)) tripToSelectTripStop = trips.get('firstObject');
      this.set('appState.selectedTripStop', tripToSelectTripStop.get('sortedDisplayableTripStops.firstObject.id'));
    }
  });
});