define('bottomline-execution/routes/edit-truck', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll('truck', { reload: true });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);

      if (model.get('length') === 1) {
        var currentTruck = model.get('firstObject');
        controller.send('select', currentTruck);
      }
    }
  });
});