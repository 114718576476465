define('bottomline-execution/serializers/trip', ['exports', 'ember-data', 'active-model-adapter'], function (exports, _emberData, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    session: Ember.inject.service('session'),
    authenticatedUser: Ember.computed.alias('session.session.content.authenticated'),
    isNewSerializerAPI: true,
    attrs: {
      trip_stops: { embedded: 'always' },
      resource: { embedded: 'always' }
    },
    serialize: function serialize(snapshot) {
      if (snapshot.attr('forReport')) {
        return this.forReport(snapshot);
      } else {
        return this.forServer(snapshot);
      }
    },
    forReport: function forReport(snapshot) {
      var truckInventories = snapshot.record.get('shift.truck_inventories').map(function (tInventory) {
        var truckInventory = Object.assign({}, tInventory.toJSON(), {
          id: tInventory.id,
          truckStorageId: tInventory.get('truck_storage_id'),
          materialId: tInventory.get('material_id'),
          truckId: tInventory.get('truck_id'),
          trailerId: tInventory.get('trailer_id'),
          currentQty: tInventory.get('currentQty'),
          maxQty: tInventory.get('maxQty'),
          minQty: tInventory.get('minQty')
        });
        return truckInventory;
      });

      var tripStops = snapshot.hasMany('trip_stops').map(function (trip_stop) {
        var tripStopStorages = trip_stop.hasMany('trip_stop_storages').map(function (tss) {
          tss._internalModel._attributes['id'] = tss.id;
          tss._internalModel._attributes['TripStopID'] = tss.attr('TripStopID');
          tss._internalModel._attributes['TripID'] = trip_stop.attr('TripID');
          tss._internalModel._attributes['StorageID'] = tss.attr('StorageID');
          tss._internalModel._attributes['MaterialID'] = tss.attr('MaterialID');
          tss._internalModel._attributes['plannedQty'] = tss.attr('plannedQty');

          var tssRecord = tss.record;

          var material = tssRecord.get('material');
          if (material) {
            tss._internalModel._attributes['material'] = material.get('data') || {};
          }

          var storage = tssRecord.get('storage');
          if (storage) {
            tss._internalModel._attributes['storage'] = Ember.getProperties(storage.get('data') || {}, ['totalStorageQty', 'SequenceNb']);
          }

          return tss._internalModel._attributes;
        });
        var tripStopTruckStorages = trip_stop.hasMany('trip_stop_truck_storages').map(function (tsts) {
          tsts._internalModel._attributes['id'] = tsts.id;
          tsts._internalModel._attributes['tripStopId'] = tsts.attr('TripStopID');
          tsts._internalModel._attributes['tripId'] = tsts.attr('TripID');
          tsts._internalModel._attributes['storageID'] = tsts.attr('StorageID');
          tsts._internalModel._attributes['materialID'] = tsts.attr('MaterialID');
          tsts._internalModel._attributes['plannedQty'] = tsts.attr('plannedQty');
          tsts._internalModel._attributes['TruckStorageID'] = tsts.attr('TruckStorageID');

          var material = tsts.record.get('material');
          if (material) {
            tsts._internalModel._attributes['material'] = material.get('data') || {};
          }

          return tsts._internalModel._attributes;
        });

        trip_stop._internalModel._attributes['id'] = trip_stop.id;
        trip_stop._internalModel._attributes['state'] = trip_stop.attr('state');
        trip_stop._internalModel._attributes['sequenceNb'] = trip_stop.attr('SequenceNb');
        trip_stop._internalModel._attributes['extOrderId'] = trip_stop.attr('ExtOrderID');
        trip_stop._internalModel._attributes['sitename'] = trip_stop.attr('sitename');
        trip_stop._internalModel._attributes['allDriverRemarks'] = trip_stop.record.get('allRemarks');
        trip_stop._internalModel._attributes['activityCode'] = trip_stop.attr('ActivityCode');
        trip_stop._internalModel._attributes['callCustomer'] = trip_stop.attr('callCustomer');
        trip_stop._internalModel._attributes['tripStopStorages'] = tripStopStorages;
        trip_stop._internalModel._attributes['tripStopTruckStorages'] = tripStopTruckStorages;

        if (trip_stop.belongsTo('site')) {
          trip_stop._internalModel._attributes['site'] = Ember.getProperties(trip_stop.belongsTo('site')._attributes, ['Name', 'Address', 'LoadingID', 'ExtInvoiceDebtorID', 'City', 'Telephone']);
        }

        return trip_stop._internalModel._attributes;
      });

      return {
        appState: Ember.getProperties(this.get('appState'), ['currentTruck', 'currentTrailer', 'language']),
        shift: snapshot.belongsTo('shift')._attributes,
        shiftId: snapshot.belongsTo('shift').id,
        displayName: this.get('authenticatedUser.user_display_name'),
        state: snapshot.attr('state'),
        truckId: snapshot.attr('TruckID'),
        Status: snapshot.attr('Status'),
        totalDuration: snapshot.attr('TotalDuration'),
        totalDistance: snapshot.attr('TotalDistance'),
        confirmedSequenceNb: snapshot.attr('ConfirmedSequenceNb'),
        confirmedStartDt: snapshot.attr('ConfirmedStartDT'),
        confirmedEndDt: snapshot.attr('ConfirmedEndDT'),
        plannedDeliveryQty: snapshot.attr('PlannedDeliveryQty'),
        plannedSequenceNb: snapshot.attr('SequenceNb'),
        plannedStartDT: snapshot.attr('PlannedStartDT'),
        odometerStart: snapshot.attr('OdometerStart'),
        odometerEnd: snapshot.attr('OdometerEnd'),
        tripStops: tripStops,
        truckInventories: truckInventories
      };
    },
    forServer: function forServer(snapshot) {
      var truckInventories = snapshot.record.get('shift.truck_inventories').filter(function (tInventory) {
        return tInventory.get('hasDirtyAttributes') || tInventory.get('isNew');
      }).map(function (tInventory) {
        var truckInventory = Object.assign({}, tInventory.toJSON(), {
          id: tInventory.id,
          truck_storage_id: tInventory.get('truck_storage_id'),
          material_id: tInventory.get('material_id'),
          truck_id: tInventory.get('truck_id'),
          trailer_id: tInventory.get('trailer_id'),
          current_qty: tInventory.get('currentQty'),
          max_qty: tInventory.get('maxQty'),
          min_qty: tInventory.get('minQty')
        });
        delete truckInventory.currentQty;return truckInventory;
      });

      var tripStops = snapshot.hasMany('trip_stops').map(function (trip_stop) {
        var tripStopStorages = trip_stop.hasMany('trip_stop_storages').filter(function (tss) {
          return tss.record.get('hasDirtyAttributes') || tss.record.get('isNew');
        }).map(function (tss) {
          tss._internalModel._attributes['id'] = tss.id;
          tss._internalModel._attributes['TripStopID'] = tss.attr('TripStopID');
          tss._internalModel._attributes['TripID'] = trip_stop.attr('TripID');
          tss._internalModel._attributes['StorageID'] = tss.attr('StorageID');
          tss._internalModel._attributes['MaterialID'] = tss.attr('MaterialID');
          tss._internalModel._attributes['startInventoryQtyPercentage'] = tss.record.get('startInventoryQtyPercentage');

          return tss._internalModel._attributes;
        });

        var tripStopTruckStorages = (trip_stop.hasMany('trip_stop_truck_storages') || []).filter(function (tsts) {
          return tsts.record.get('hasDirtyAttributes') || tsts.record.get('isNew');
        }).map(function (tsts) {
          tsts._internalModel._attributes['id'] = tsts.id;
          tsts._internalModel._attributes['TripStopID'] = tsts.attr('TripStopID');
          tsts._internalModel._attributes['TripID'] = tsts.attr('TripID');
          tsts._internalModel._attributes['StorageID'] = tsts.attr('StorageID');
          tsts._internalModel._attributes['MaterialID'] = tsts.attr('MaterialID');

          return tsts._internalModel._attributes;
        });

        var siteStorageInventories = [];
        var site = trip_stop.record.get('site');
        if (trip_stop.record.get('isUnload') && Ember.isPresent(site)) {
          site.get('storages').reduce(function (inventories, storage) {
            var inventory = storage.get('recent_inventory');

            if (Ember.isPresent(inventory) && (inventory.get('hasDirtyAttributes') || inventory.get('isNew')) && inventory.get('inventoryQty') > 0) {
              inventory.get('_internalModel')._attributes['id'] = inventory.get('id');
              inventory.get('_internalModel')._attributes['StorageID'] = inventory.get('StorageID');
              inventory.get('_internalModel')._attributes['InventoryQty'] = inventory.get('inventoryQty');
              inventory.get('_internalModel')._attributes['InventoryDT'] = inventory.get('InventoryDT');

              inventories.push(inventory.get('_internalModel')._attributes);
            }

            return inventories;
          }, siteStorageInventories);
        }

        if (Ember.isPresent(trip_stop.changedAttributes().latitude) || Ember.isPresent(trip_stop.changedAttributes().longitude)) {
          trip_stop._internalModel._attributes['trip_stop_site_attributes'] = {
            latitude: trip_stop.attr('latitude'),
            longitude: trip_stop.attr('longitude')
          };
        }

        trip_stop._internalModel._attributes['id'] = trip_stop.id;
        trip_stop._internalModel._attributes['state'] = trip_stop.attr('state');
        trip_stop._internalModel._attributes['trip_stop_storage_attributes'] = tripStopStorages;
        trip_stop._internalModel._attributes['trip_stop_truck_storage_attributes'] = tripStopTruckStorages;
        if (Ember.isPresent(siteStorageInventories)) {
          trip_stop._internalModel._attributes['site_storage_inventory_attributes'] = siteStorageInventories;
        }

        return trip_stop._internalModel._attributes;
      });

      return {
        state: snapshot.attr('state'),
        Status: snapshot.attr('Status'),
        ConfirmedSequenceNb: snapshot.attr('ConfirmedSequenceNb'),
        ConfirmedStartDT: snapshot.attr('ConfirmedStartDT'),
        ConfirmedEndDT: snapshot.attr('ConfirmedEndDT'),
        OdometerStart: snapshot.attr('OdometerStart'),
        OdometerEnd: snapshot.attr('OdometerEnd'),
        trip_stop_attributes: tripStops,
        truck_inventory_attributes: truckInventories
      };
    }
  });
});